import {Component, ElementRef, Input} from '@angular/core';
import {Message} from "../chat.service";
import {FormControl, FormsModule} from "@angular/forms";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from "@angular/material/icon";
import {AUTHOR} from "../chatbot.component";
import {ThinkingLoaderComponent} from "../thinking-loader/thinking-loader.component";
import {CONVERSATION_STYLE} from "../hallucination/conversation-style";
import {DateTime} from 'luxon';
import {ChatbotShareQueryDialogComponent} from "../../../dialogs/chatbot-share-query-dialog/chatbot-share-query-dialog.component";
import {environment as ENV} from "../../../../environments/environment";
import {SafeHtmlPipe} from '../../../pipes/safe-html/safe-html.pipe';

@Component({
  selector: 'iv-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    NgOptimizedImage,
    ThinkingLoaderComponent,
    SafeHtmlPipe
  ],
})
export class MessageComponent {
  isChatterBotAuthor = true;
  messageText: string = '';
  timestamp: any;
  errorStatusCode: number = 0;
  @Input() conversationStyle = new FormControl<CONVERSATION_STYLE>(CONVERSATION_STYLE.BALANCED);
  @Input() set message(message: Message) {
    const { author, user_input } = message;
    this.isChatterBotAuthor = author === AUTHOR.CHATBOT;
    this.messageText = user_input;
    if(!this.isChatterBotAuthor) {
      this.timestamp = message.timestamp.toLocal().toFormat('hh:mm a');
    }
    if(message.hasOwnProperty('errorStatusCode') && (typeof(message.errorStatusCode) === 'number') && (message.errorStatusCode > 0)) {
      this.errorStatusCode = message.errorStatusCode;
    } else {
      this.errorStatusCode = 0;
    }
  }

  isProduction: boolean = false;

  constructor(
    private dialog: MatDialog,
  ) {
    this.isProduction = ENV.production !== undefined ? ENV.production : false;
  }

  public showChatbotQueryShareDialog($event: any, message: string) {
    const dialogRef = this.dialog.open(ChatbotShareQueryDialogComponent, {
      panelClass: 'iv-chatbot-share-query-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: {messageText: this.messageText}
    });
  }

  protected readonly CONVERSATION_STYLE = CONVERSATION_STYLE;
}
