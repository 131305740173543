import {Component, inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";

import {NgStringPipesModule} from "ngx-pipes";
import {Subscription} from "rxjs";

import {Constants} from "../../../constants/constants";
import {Research} from "../../../interface/research";

import {AzureSearchService} from '../../../services/azuresearch/azuresearch.service';
import {DashboardService} from '../../../services/dashboard/dashboard.service';
import {SearchService} from '../../../services/search/search.service';
import {SharedService} from "../../../services/shared/shared.service";
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'iv-filters-chip',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatIconModule, MatTooltipModule, NgStringPipesModule],
  templateUrl: './filters-chip.component.html',
  styleUrls: ['./filters-chip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersChipComponent implements OnInit, OnDestroy, OnChanges {

  public azureSearchService = inject(AzureSearchService);

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  chatbotChipPrettyTextSubscription: Subscription = Subscription.EMPTY;
  chipPrettyText: string = '';

  chatbotFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  private userPermissions: any = null;

  constructor(
    public dashboardService: DashboardService,
    public searchService: SearchService,
    private sharedService: SharedService,
    private userService: UserService,
  ) {

    // subscribe to store for ui data per research collection
    this.researchSubscription = this.searchService.research$.subscribe((research) => {
      this.research = research;
      let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === Constants.ChatbotFiltersStores[0]);
      if(newResearchItem !== undefined) {
        this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
      }
    });

  }

  ngOnInit(): void {

    this.userPermissions = this.userService.getUserPermissions().permissions;

    this.chatbotChipPrettyTextSubscription = this.sharedService.chatbotChipPrettyText$.subscribe((text: string) => {
      this.chipPrettyText = text;
    });

    this.chatbotFacetsPrettyTextSubscription = this.sharedService.chatbotFacetsPrettyText$.subscribe((text: string) => {
      this.facetsPrettyText = text;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public removeChip(): void {
    this.azureSearchService.set_first_results_fetched(Constants.ChatbotFiltersStores[0], false);
    this.dashboardService.clearAllFilters(Constants.ChatbotFiltersStores[0]);
    this.searchService.ss_set_collection(Constants.ChatbotFiltersStores[0]);
  }

  public activateChip(): void {
    this.dashboardService.updateShowChatbotFacets(true);
  }

  public copyReportsFilters() {
    this.azureSearchService.clear_all_facets( Constants.ChatbotFiltersStores[0], false );

    // get the facets diff from Reports but strip out all the facets that are not whitelisted!
    const reportsFacetsDiff = this.azureSearchService.get_facetsdiff('Reports');
    reportsFacetsDiff.facets = reportsFacetsDiff.facets.filter((facet: any) => Constants.chatbotFiltersWhitelist.includes(facet.key));

    // clamp minimum date
    reportsFacetsDiff.facets.forEach((facet: any) => {
      if(facet.key === 'publishedDate') {
        const startDate: Date = new Date(facet.min);
        const chatbotMinDate: Date = new Date(Constants.chatbotMinDate);
        if(startDate < chatbotMinDate) {
          facet.min = chatbotMinDate.toISOString();
          facet.filterLowerBound = chatbotMinDate.toISOString();
        }
      }
    });

    // disable filters: my-subscriptions, and any others the users does not have acls for
    reportsFacetsDiff.subscriptionFilters.filters.forEach((filter: any) => {
      if(filter.key === 'my-subscriptions') {
        filter.active = false;
      } else {
        const subFilter: string = this.searchService.getSubscriptionFilterByKey(filter.key);
        if(subFilter.length) {
          const regex: RegExp = /'([^']+)'/g;
          let match;
          const acls: string[] = [];
          while (match = regex.exec(subFilter)) {
            acls.push(match[1]);
          }
          if( !acls.some((acl: string) => this.userPermissions.includes(acl)) ) {
            filter.active = false;
          }
        }
      }
    });

    this.searchService.ss_set_subscription( Constants.ChatbotFiltersStores[0], '', false );
    this.azureSearchService.set_facets_diff( Constants.ChatbotFiltersStores[0], reportsFacetsDiff );
    this.azureSearchService.facetsDiff_to_facets( Constants.ChatbotFiltersStores[0], true );
    this.searchService.ss_set_collection( Constants.ChatbotFiltersStores[0] );
  }

  ngOnDestroy() {
    this.researchSubscription.unsubscribe();
    this.chatbotChipPrettyTextSubscription.unsubscribe();
    this.chatbotFacetsPrettyTextSubscription.unsubscribe();
  }

}
