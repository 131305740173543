import { Injectable } from '@angular/core';
import {BehaviorSubject, first, Observable, of, Subject} from "rxjs";

import { Constants } from "../../constants/constants";
const livefeedStores: string[] = Constants.LivefeedStores;
const reportsStores: string[] = Constants.ReportsStores;
const researchSpotlightStores: string[] = Constants.ResearchSpotlightStores;
const chatbotFiltersStores: string[] = Constants.ChatbotFiltersStores;

import { AzureSearchService } from "../azuresearch/azuresearch.service";
import { DataService } from "../data/data.service";
import { LogService } from "../log/log.service";
import { SavedFiltersService } from "../savedfilters/savedfilters.service";
import { SearchService } from "../search/search.service";
import { SharedService } from "../shared/shared.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  // layout control
  readonly mainLayouts: string[] = [ 'Panes', 'ResearchDetail' ];
  private _mainLayoutMode: BehaviorSubject<string> = new BehaviorSubject<string>('Panes');

  // livefeed observables
  private _showLivefeedFacets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private clearLivefeedSearchBoxSubject: Subject<boolean> = new Subject<boolean>();
  public clearLivefeedSearchBox$: Observable<boolean> = this.clearLivefeedSearchBoxSubject.asObservable();

  // reports observables
  private _showReportFacets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private clearReportsSearchBoxSubject: Subject<boolean> = new Subject<boolean>();
  public clearReportsSearchBox$: Observable<boolean> = this.clearReportsSearchBoxSubject.asObservable();

  // research details
  private _researchDetailsDocId = new BehaviorSubject<string>('');
  researchDetailsDoc$: Observable<any> = of({});

  // chatbot observables
  private _showChatbotFacets: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private azureSearchService: AzureSearchService,
    private dataService: DataService,
    private logService: LogService,
    private savedFiltersService: SavedFiltersService,
    private searchService: SearchService,
    private sharedService: SharedService,
  ) {

    // initialize stores for all panes
    let stores: string[] = reportsStores.concat(livefeedStores).concat(researchSpotlightStores).concat(chatbotFiltersStores);
    this.searchService.init_stores(stores);

  }

  // --------------------------------------------------------------------------
  //  Layout Control
  // --------------------------------------------------------------------------

  get mainLayoutMode() {
    return this._mainLayoutMode.asObservable();
  }

  updateMainLayoutMode(mode: string) {
      if(this.mainLayouts.includes(mode)) {
        this._mainLayoutMode.next(mode);
      }
  }

  // --------------------------------------------------------------------------
  //  Reports Pane
  // --------------------------------------------------------------------------

  get showReportFacets() {
    return this._showReportFacets.asObservable();
  }

  updateShowReportFacets(value: boolean) {
    this._showReportFacets.next(value);
  }

  clearReportsSearchBox() {
    this.clearReportsSearchBoxSubject.next(true);
  }

  // --------------------------------------------------------------------------
  //  Livefeed Pane
  // --------------------------------------------------------------------------

  get showLivefeedFacets() {
    return this._showLivefeedFacets.asObservable();
  }

  updateShowLivefeedFacets(value: boolean) {
    this._showLivefeedFacets.next(value);
  }

  clearLivefeedSearchBox() {
    this.clearLivefeedSearchBoxSubject.next(true);
  }

  // --------------------------------------------------------------------------
  //  Chatbot
  // --------------------------------------------------------------------------

  get showChatbotFacets() {
    return this._showChatbotFacets.asObservable();
  }

  updateShowChatbotFacets(value: boolean) {
    this._showChatbotFacets.next(value);
  }

  // --------------------------------------------------------------------------
  //  Research Details
  // --------------------------------------------------------------------------

  get researchDetailsDocId() {
    return this._researchDetailsDocId.asObservable();
  }

  updateResearchDetailsDocId(docId: string) {
    if((typeof(docId) === 'string') && docId.length) {
      this._researchDetailsDocId.next(docId);
      this.updateResearchDetailsDoc(docId);
    }
  }

  updateResearchDetailsDoc(docId: string) {
    if((typeof(docId) === 'string') && docId.length) {
      this.researchDetailsDoc$ = this.dataService.getResearchMetaData(docId);
    }
  }

  // --------------------------------------------------------------------------
  //  New Research
  // --------------------------------------------------------------------------

  clearSearchBox(storeKey: string): void {
    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.clearReportsSearchBox();

    } else {
      if( livefeedStores.indexOf(storeKey) >= 0 ) {
        this.clearLivefeedSearchBox();

      }
    }
  }

  clearAllFilters(storeKey: string): void {

    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.clearReportsSearchBox();
      this.sharedService.updateReportsSavedUrlFilter('');
      this.searchService.clearQueryParams(Constants.reportsSavedFiltersUrl);
      this.searchService.removeQueryParam(Constants.reportsSavedFiltersUrl);

    } else {
      if( livefeedStores.indexOf(storeKey) >= 0 ) {
        this.clearLivefeedSearchBox();
        this.sharedService.updateLivefeedSavedUrlFilter('');
        this.searchService.clearQueryParams(Constants.livefeedSavedFiltersUrl);
        this.searchService.removeQueryParam(Constants.livefeedSavedFiltersUrl);

      }
    }
    this.searchService.setQuery(storeKey, '');
    this.searchService.setChipContents(storeKey, '');
    this.searchService.setPage(storeKey, 1);
    this.azureSearchService.clear_all_facets(storeKey);
    this.savedFiltersService.clearFilters(storeKey);
    this.sharedService.clearActiveFilter(storeKey,
      this.azureSearchService.get_facetsdiff(storeKey, Constants.facetsModifiedIgnoreDates || false)
    );
  }

  public downloadAttachment(id: string, model: string) {
    console.log(`dashboard.service::downloadAttachment(${id}, ${model})`);
    this.dataService.getAttachments(id.toString())
      .subscribe(data => {
        if (data.url.length !== 0) {
          let file = data.url.filter((x: any) => x.azure_url_filename === model)[0].azure_url;
          let downloadlink: any = document.createElement('A');
          downloadlink.href = file;
          let filename = data.url.filter((x: any) => x.azure_url_filename === model)[0].azure_url_filename;  // BUG?
          downloadlink.download = filename;
          document.body.appendChild(downloadlink);
          downloadlink.click();
          document.body.removeChild(downloadlink);

          this.logService.article = data.result;
          this.logService.track("attachment_downloaded", true,{
            article_filename: filename
          });

          this.logService.logPendo('Attachment Download', {
            id: data.result.id,
            key: data.result.key,
            title: data.result.title,
            series: data.result.series,
            type: data.result.projectType,
            collection: data.result.collection,
            primaryAuthor: (data.result.primaryAuthor) ? (data.result.primaryAuthor.displayName) : '',
            authors: data.result.authors,
            publishedDate: data.result.publishedDate,
            filename: filename
          });

        }
      }, err => {
      });
  }

}
