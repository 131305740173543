import {inject, Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {WebsocketService} from './websocket.service';
import {map} from 'rxjs/operators';
import {environment as ENV} from '../../../environments/environment';
import {SharedService} from '../../services/shared/shared.service';

const CHAT_URL = ENV.API.chatBot.websocket;

export interface Reference {
  published_date: string;
  reference_id: string;
  referenced: boolean;
  source: string;
  title: string;
  url: string;
  type: string;
}

export interface Message {
  action?: string;
  user_input: string;
  authorizer?: string;
  conversation_id?: string;
  author?: string;
  prompt_id?: string;
  references?: Reference[];
  quotedReferenceList?: Reference[];
  noQuotedReferenceList?: Reference[];
  prismReferenceList?: Reference[];
  ratingExpanded?: boolean;
  product?: string;
  temperature?: string | null;
  refine_prompt?: boolean | null;
  filters?: any;
  client?: string;
  timestamp?: any;
  header?: string;
  error?: boolean;
  errorStatusCode?: number;
}

@Injectable()
export class ChatService {
  public messages: Subject<Message> = new Subject<Message>();

  public wsService = inject(WebsocketService);

  meSubject: Subscription = Subscription.EMPTY;
  msgEvent: MessageEvent<any> = null as any;

  constructor(
    private sharedService: SharedService
  ) {

    this.wsService.connect(CHAT_URL);
    this.meSubject = this.sharedService.chatbotMessageEvent$.subscribe((messageEvent: MessageEvent) => {
      try {
        const data = JSON.parse(messageEvent.data);

        // Assuming you want to create a new message object here
        const message: Message = {
          author: 'chatbot',
          user_input: '', // Populate based on your logic
        };

        if (data.hasOwnProperty('data') && data.data.hasOwnProperty('references') && data.data.references) {
          message.user_input = 'References';
          message.references = data.data.references;
          if (message.references) {
            message.references.forEach((ref: any) => {
              if (ref.url && ref.url.startsWith('http')) {
                if (ref.type !== 'Prism') {
                  const url: URL = new URL(ref.url);
                  if (url.searchParams) {
                    ref.url = url.pathname + '?' + url.searchParams;
                  } else {
                    ref.url = url.pathname;
                  }
                }
              }
            });
          }
        }
        // if (Array.isArray(data.data) && data.data.includes('##END_CONVERSATION##')) {
        if (data.data === '##END_CONVERSATION##') {
          message.author = 'chatbot-end';
          message.user_input = '';
          message.prompt_id = data.prompt_id;
        } else {
          if ((typeof (data.data) == 'string') && data.error) {
            message.author = 'chatbot';  // s/b chatbot-error?
            message.user_input = data.data;
            message.prompt_id = data.prompt_id;
            message.error = true;
            message.errorStatusCode = data.code;
          } else {
            message.user_input = data.data;
          }
        }

        // Here, instead of trying to cast the message to a Subject, you directly work with the message object.
        // If `this.messages` is supposed to be a Subject<Message>, you would next() the new message into it like so:
        if (this.messages) {
          this.messages.next(message);
        }
      } catch (error) {
        console.log(error);
        // Handle error, perhaps by emitting an error message through `this.messages` if it's a Subject<Message>
        if (this.messages) {
          this.messages.next({
            author: 'chatbot-error',
            user_input: 'Response Error',
          });
        }
      }
    });

  }

  public sendMessage(message: Message): void {
    const messagePayload: string = JSON.stringify(message);
    this.wsService.send(messagePayload);
  }

  public reconnect(): void {
    this.wsService.connect(CHAT_URL);
  }

}
