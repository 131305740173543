import {inject, Injector, NgModule, runInInjectionContext} from '@angular/core';
import {CanActivateFn, RouterModule, Routes} from '@angular/router';
import {Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel} from '@angular/router';
import {CallbackComponent} from './pages/callback/callback.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {SummaryLivefeedComponent} from './pages/summary-livefeed/summary-livefeed.component';
import {ResearchDetailComponent} from './pages/research-detail/research-detail.component';
import {researchDetailResolver} from './pages/research-detail/research-detail.resolver';
import {InteractiveReportComponent} from './pages/interactive-report/interactive-report.component';
import {InteractiveReportResolver} from './pages/interactive-report/interactive-report.resolver';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {FourOFourPageComponent} from './pages/four-o-four-page/four-o-four-page.component';
import {concatMap, firstValueFrom, from, last, Observable, of, Subscription, takeWhile} from 'rxjs';
import {authGuardFn} from '@auth0/auth0-angular';
import {summaryMetadataResolver} from './resolvers/summary-metadata.resolver';
import {PreviewRestrictedComponent} from './pages/preview-restricted/preview-restricted.component';
import {HelpComponent} from './pages/help/help.component';
import {PreferencesComponent} from './pages/preferences/preferences.component';
import {DownloadComponent} from './pages/download/download.component';
import {authorizationGuard} from './guards/authorization.guard';
import {FourOThreePageComponent} from './pages/four-o-three-page/four-o-three-page.component';
import {FourOFourAlertidPageComponent} from "./pages/four-o-four-alertid-page/four-o-four-alertid-page.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'error/:code',
    component: ErrorPageComponent,
  },
  {
    path: '403',
    component: FourOThreePageComponent,
  },
  {
    path: '404',
    component: FourOFourPageComponent,
  },
  {
    path: '404/alertid/:alertId',
    component: FourOFourAlertidPageComponent,
  },
  {
    path: 'preview',
    component: PreviewRestrictedComponent,
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'dashboard',
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    component: DashboardComponent,
  },
  {
    path: 'dashboard/iaq/:iaq',
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    component: DashboardComponent,
  },
  {
    path: 'preferences',
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    component: PreferencesComponent,
  },
  {
    path: 'help',
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    component: HelpComponent,
  },
  {
    path: 'research/:docId',
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    component: ResearchDetailComponent,
    resolve: {
      metadata: researchDetailResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'research/:docId/interactive',
    component: InteractiveReportComponent,
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    resolve: {
      metadata: InteractiveReportResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'research/:docId/:section',
    component: ResearchDetailComponent,
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    resolve: {
      metadata: researchDetailResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'summary/livefeed/:docId',
    component: SummaryLivefeedComponent,
    resolve: {
      data: summaryMetadataResolver
    },
  },
  {
    path: 'download',
    canActivate: [SequentialGuards([authGuardFn, authorizationGuard])],
    component: DownloadComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

function SequentialGuards(guards: CanActivateFn[]): CanActivateFn {
  return (route, state) => {
    const injectionContext = inject(Injector);
    // Convert an array into an observable.
    return from(guards).pipe(
      // For each guard, fire canActivate and wait for it to complete.
      concatMap((guard) => {
        return runInInjectionContext(injectionContext, () => {
          var guardResult = guard(route, state);
          if (guardResult instanceof Observable) {
            return firstValueFrom(guardResult);
          } else if (guardResult instanceof Promise) {
            return from(guardResult);
          } else {
            return of(guardResult);
          }
        });
      }),
      // Don't execute the next guard if the current guard's result is not true.
      takeWhile((value) => value === true, true),
      // Return the last guard's result.
      last()
    );
  };
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    // enableTracing: true
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {

  debug_routes: boolean = false;        // show debug routing information
  private routerSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private router: Router
  ) {

    // show debug routing information if enabled
    if (this.debug_routes) {
      this.routerSubscription = router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          console.log('Navigation started');
          console.log(event);
        }
        if (event instanceof NavigationEnd) {
          console.log('Navigation ended');
          console.log(event);
        }
        if (event instanceof NavigationError) {
          console.log('Navigation error', event.error);
          console.log(event);
        }
        if (event instanceof NavigationCancel) {
          console.log('Navigation canceled');
          console.log(event);
        }
      });
    }

  }

  ngOnDestroy() {

    if (this.debug_routes) {
      if (this.routerSubscription !== Subscription.EMPTY) {
        this.routerSubscription.unsubscribe();
      }
    }

  }

}
