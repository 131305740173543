import {Component, inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";

import {Constants} from "../../constants/constants";
import {AzssError} from "../../interface/azss";

import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {MatTooltipModule} from "@angular/material/tooltip";

import {DateFilterComponent} from "../date-filter/date-filter.component";
import {SubscriptionFilterComponent} from "../subscription-filter/subscription-filter.component";
import {KeywordSearchHelpComponent} from "../../dialogs/keyword-search-help/keyword-search-help.component";

import {AzureSearchService} from '../../services/azuresearch/azuresearch.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {SearchService} from '../../services/search/search.service';
import {SharedService} from '../../services/shared/shared.service';
import {UserService} from '../../services/user/user.service';

import {SearchPipe} from "../../pipes/search/search.pipe";

@Component({
  selector: 'iv-chatbot-filters',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    DateFilterComponent,
    FormsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatTooltipModule,
    SearchPipe,
    SubscriptionFilterComponent,
    ReactiveFormsModule
  ],
  templateUrl: './chatbot-filters.component.html',
  styleUrls: ['./chatbot-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatbotFiltersComponent implements OnInit {
  @Input() pane: string | undefined;
  @Input() store?: any;

  public azureSearchService = inject(AzureSearchService);

  searchCtrl = new FormControl();       // keyword search input box
  filterNameCtrl = new FormControl()    // filter name input box

  // the currently opened filter (mat-expansion-panel)
  activePanel: string = '';

  private userPermissions: any = null;

  constructor(
    public dialog: MatDialog,
    public dashboardService: DashboardService,
    public searchService: SearchService,
    public sharedService: SharedService,
    private userService: UserService,
  ) {

  }

  ngOnInit(): void {
    this.userPermissions = this.userService.getUserPermissions().permissions;
  }

  public clearFilter() {
    this.dashboardService.clearAllFilters(this.store.collection);
  }

  public mapFilterTitle(key: string) {
    const titlesMap: any = {
      savedfilters: 'Saved Filters',
      publishedDate: 'Date Range',
      projectType: 'Intelligence Type',
      acl: 'Intelligence Type',
      series: 'Report Series',
      researchType: 'RS Research Type',
      type: 'Class',
      subType: 'Sub Class',
      category: 'Category',
      subCategory: 'Sub Category',
      solutionSet: 'Solution Set',
      modelAuthors: 'Authors',
      authors: 'Authors',
      companies: 'Companies',
      stockTickers: 'Stock Tickers',
      countries: 'Countries',
      regions: 'Regions',
      basins: 'Basins',
      plays: 'Plays',
      intervals: 'Intervals',
      financialView: 'Financial View',
      keywords: 'Keywords'
    };
    return titlesMap[key] || key;
  }

  public setActivePanel(panel: string) {
    this.activePanel = panel;
  }

  searchFromFacet(storeKey: string, facet: any, facetValue:any, logStatus: boolean): void {
    this.searchService.ss_search_from_facet(storeKey, facet, facetValue);
  }

  setAllFacetCheckboxes($event: any, facet: any, state: boolean) {
    const searchTerm: string = (this as any)[facet.key] as string;
    this.azureSearchService.set_all_facet_checkboxes(this.store.collection, facet.key, state, searchTerm);
    this.searchService.setPage(this.store.collection, 1);
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });
    setTimeout(() => {
      $event.source.toggle();
    }, 500);
  }

  onCombineOptionChange(event: any, facet: any) {
    this.azureSearchService.set_facets_group_combine_using_and(this.store.collection, facet.key,
      event.value !== 'or');
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });
  }

  clearSearchField(facetKey: string): void {
    (this as any)[facetKey] = '';
  }

  public clearSearchText(event: any) {
    this.searchService.setQuery(this.store.collection, '');
    this.sharedService.updateActiveFilterParameter(this.store.collection, 'q', '');
    setTimeout(() => {
      this.searchCtrl.setValue('');
    });
    this.searchService.navigateToPage(this.store.collection, '', 1);
    this.searchService.checkActiveFilterChanged(this.store.collection, this.filterNameCtrl.value);
  }

  public chatbotFiltersClose() {
    this.dashboardService.updateShowChatbotFacets(false);
  }

  public keywordSearchHelp(event: any){
    // placeholder
    if (event) {
      event.stopPropagation();
    }

    const dialogRef = this.dialog.open(KeywordSearchHelpComponent, {
      panelClass: 'rsv-filter-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: ''
    });
  }

  public filterIsWhitelisted(facetKey: string): boolean {
    return Constants.chatbotFiltersWhitelist.includes(facetKey);
  }

  public testPermissions(filterString: string) {
    const regex: RegExp = /'([^']+)'/g;
    let match;
    const acls: string[] = [];
    while (match = regex.exec(filterString)) {
      acls.push(match[1]);
    }
    return acls.some((acl: string) => this.userPermissions.includes(acl));
  }

}
