import {Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataService} from "../../services/data/data.service";
import {LogService} from "../../services/log/log.service";
import {SearchService} from "../../services/search/search.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {NotificationService} from "../../services/notification/notification.service";
import {Constants} from "../../constants/constants";

@Component({
  selector: 'iv-chatbot-share-query-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatInputModule, ReactiveFormsModule, MatIconModule, MatTooltipModule],
  templateUrl: './chatbot-share-query-dialog.component.html',
  styleUrls: ['./chatbot-share-query-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatbotShareQueryDialogComponent implements OnInit {
  shareForm = new FormGroup({
    chatbotQuery: new FormControl(''),
    emails: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._%+-]+@[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)(,\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9._%+-]+@[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+))*$/)
    ]),
    message: new FormControl(null)
  });

  error = false;
  success = false;
  emailList: any;

  @ViewChild('copyIcon', {read: ElementRef}) copyIcon: ElementRef | undefined;

  chatbotQuery: string = '';
  currentProtocol: string = '';
  currentDomain: string = '';
  currentPort: string = '';
  chatbotQueryUrl: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChatbotShareQueryDialogComponent>,
    private dataService: DataService,
    private logService: LogService,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private searchService: SearchService,
  ) {
    this.chatbotQuery = data.messageText;

    this.currentProtocol = window.location.protocol;
    this.currentDomain = window.location.hostname;
    this.currentPort = window.location.port;

  }

  ngOnInit() {
    this.shareForm.get('chatbotQuery')?.setValue(this.chatbotQuery);
  }

  get f() {
    return this.shareForm.controls;
  }

  getQueryUrl(payload: any) : Promise<string | void> {
    return new Promise((resolve, reject) => {
      const url: string = this.searchService.queryUrl(payload.data.query);
      this.dataService.saveIAQuery(url, {'query': payload.data.query}).then((result: any) => {
        result.result.url = url;
        resolve(result);
      }, err => {
        // this.notificationService.open('An error occurred creating the Instant Analyst query share.', '', 5000, 'success');
        reject('');
      });
    });
  }

  async copyToClipboard() {
    if (this.copyIcon) {
      const originalIcon = this.copyIcon.nativeElement.textContent;

      if (this.shareForm.value && this.shareForm.value.chatbotQuery) {

        const payload: any = {
          data: {query: this.shareForm.value.chatbotQuery, url: this.data.url}
        };

        await this.getQueryUrl(payload).then((result: any) => {
          const url: string = `${this.currentProtocol}//${this.currentDomain}${this.currentPort.length && this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard/${Constants.chatbotQuery}/${result.result.url}`;
          this.clipboard.copy(url);
          if (this.copyIcon) {
            this.copyIcon.nativeElement.textContent = 'check';
          }

          this.logService.track("ia_prompt_share", false, {
            query: payload.data.query,
            url: `${this.currentProtocol}//${this.currentDomain}${this.currentPort.length && this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard/${Constants.chatbotQuery}/${result.result.url}`
          });

        }, err => {
          this.success = false;
          this.error = true;
        });
      }

      setTimeout(() => {
        if (this.copyIcon) {
          this.copyIcon.nativeElement.textContent = originalIcon;
        }
      }, 2000);
    }
  }

  async onSubmit() {
    if (this.shareForm.valid) {
      this.emailList = this.shareForm.value.emails;
      if(this.shareForm.value.chatbotQuery && this.shareForm.value.chatbotQuery.length) {

        const payload: any = {
          emails: this.shareForm.value.emails,
          message: this.shareForm.value.message,
          data: {query: this.shareForm.value.chatbotQuery}
        };

        await this.getQueryUrl(payload).then((result: any) => {

          payload.data.url = `${this.currentProtocol}//${this.currentDomain}${this.currentPort.length && this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard/${Constants.chatbotQuery}/${result.result.url}`;

          this.dataService.shareQuery(payload).subscribe({
            next: (v: any) => {
              this.error = false;
              this.success = true;

              this.logService.track("ia_prompt_share", false, {
                query: payload.data.query,
                url: payload.data.url,
                sender_message: payload.message,
                recipient_email: payload.emails
              });

            },
            error: (e: any) => {
              this.success = false;
              this.error = true;
            }

          });

        }, err => {
          this.success = false;
          this.error = true;
        });

      }

    }
  }

  closeShareDialog() {
    this.dialogRef.close();
  }

}
