<div class="container">
  <div (click)="chatbotFiltersClose()"></div>
  <div class="filter-container">
    <header>
      <h1>Instant Analyst Filters</h1>
      <button (click)="chatbotFiltersClose()" class="close">
        <mat-icon>close</mat-icon>
      </button>
    </header>
    <section>
      <button class="clear-filters" (click)="clearFilter(); searchService.ss_set_collection(store.collection);">Clear All Filters</button>
      <mat-accordion class="filters">
        <!-- search filters -->
        <ng-container *ngFor="let facet of store.facets?.facets">
          <mat-expansion-panel *ngIf="(facet.values?.length > 0 || facet.type === 'RangeFacet') && (facet?.key !== 'intervals') && filterIsWhitelisted(facet?.key)" [expanded]="(activePanel === facet?.key)">
            <mat-expansion-panel-header (click)="setActivePanel(facet?.key)">
              <mat-panel-title>
                {{mapFilterTitle(facet?.key)}}
              </mat-panel-title>
              <mat-panel-description *ngIf="store.facetCount[facet?.key] > 0 || (facet?.key === 'acl' && azureSearchService.get_subscription_filters_count(store.collection) > 0)">
              <span class="filtered-crumb">
              <svg type="filter" height="16px" width="16px" viewBox="0 0 24 24" fill-rule="evenodd" vector-effect="non-scaling-stroke" style="overflow: visible; pointer-events: auto;"><path d="M 21.746094 0 L 2.253906 0 C 0.253906 0 -0.753906 2.425781 0.660156 3.839844 L 8.25 11.433594 L 8.25 19.5 C 8.25 20.207031 8.582031 20.875 9.148438 21.375 L 12.148438 23.582031 C 13.617188 24.605469 15.75 23.652344 15.75 21.78125 L 15.75 11.433594 L 23.339844 3.839844 C 24.753906 2.429688 23.75 0 21.746094 0 Z M 13.5 10.5 L 13.5 21.75 L 10.5 19.5 L 10.5 10.5 L 2.25 2.25 L 21.75 2.25 Z M 13.5 10.5"></path></svg>
              <ng-container *ngIf="facet?.key !== 'acl'">({{store.facetCount[facet?.key]}})</ng-container>
              <ng-container *ngIf="facet?.key === 'acl'">({{azureSearchService.get_subscription_filters_count(store.collection)}})</ng-container>
              </span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <iv-date-filter *ngIf="facet?.key === 'publishedDate'" [facet]="facet" [storeKey]="store.collection" [isGlobal]="false"></iv-date-filter>
            <ng-container *ngIf="facet?.key === 'acl'">
              <iv-subscription-filter *ngIf="facet?.key === 'acl'" [pane]="pane" [isGlobal]="false"></iv-subscription-filter>
            </ng-container>
            <ng-container *ngIf="(facet?.key !== 'acl') && (facet?.key !== 'publishedDate')">
              <div class="facets-spacer"></div>
              <div class="facetsCombiner">
                <div>
                  <ng-container *ngIf="facet?.key !== 'acl'">
                    <div class="search-filters">
                      <input placeholder="Search {{mapFilterTitle(facet?.key)}}" [(ngModel)]="this[facet?.key]">
                      <button  (click)="clearSearchField(facet?.key)" class="searchresults">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ul *ngIf="(facet?.key !== 'publishedDate') && (facet?.key !== 'acl')">
              <ng-container *ngFor="let facetValue of facet.values">
                <li *ngIf="facetValue.selected" class="active" (click)="searchFromFacet(store.collection, facet, facetValue, false)">
                  {{facetValue.value}} ({{facetValue.count}})
                </li>
              </ng-container>
              <ng-container *ngIf="facet?.key as facetKey">
                <ng-container *ngFor="let facetValue of facet.values | search:'key,value':this[facet?.key]">
                  <li *ngIf="!facetValue.selected" (click)="searchFromFacet(store.collection, facet, facetValue, true)">
                    {{facetValue.value}} ({{facetValue.count}})
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </section>
  </div>
  <div (click)="chatbotFiltersClose()"></div>
</div>
