import {Component, HostBinding, inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

import {NgProgressModule} from 'ngx-progressbar';
import {environment as ENV} from '../../../environments/environment';

import {Subscription} from 'rxjs';

import {Constants} from "../../constants/constants";

import {DashboardService} from '../../services/dashboard/dashboard.service';
import {DataService} from "../../services/data/data.service";
import {SavedFiltersService} from '../../services/savedfilters/savedfilters.service';
import {SnackbarArticlesService} from "../../services/snackbararticles/snackbararticles.service";

import {LivefeedNavComponent} from '../livefeed-nav/livefeed-nav.component';
import {NavbarComponent} from '../../features/navbar/navbar.component';
import {ReportsNavComponent} from '../reports-nav/reports-nav.component';
import {ResearchDetailComponent} from '../research-detail/research-detail.component';
import {ResearchSpotlightComponent} from '../../features/research-spotlight/research-spotlight.component';
import {WidgetsAreaComponent} from '../../features/widgets-area/widgets-area.component';
import {MatIconModule} from '@angular/material/icon';
import {LogService} from '../../services/log/log.service';
import {SharedService} from "../../services/shared/shared.service";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'iv-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    LivefeedNavComponent,
    NavbarComponent,
    NgProgressModule,
    ReportsNavComponent,
    ResearchDetailComponent,
    ResearchSpotlightComponent,
    WidgetsAreaComponent,
    MatIconModule
  ],
  // providers: [SearchService],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {

  // main.service: controls main layout mode
  mainLayoutModeSub: Subscription = Subscription.EMPTY;
  mainLayoutMode: string = '';
  @HostBinding('class') class = 'reports';

  profile: any = null;

  reportsStores: string[] = Constants.ReportsStores;
  livefeedStores: string[] = Constants.LivefeedStores;

  constructor(
    private route: ActivatedRoute,
    public dashboardService: DashboardService,
    private dataService: DataService,
    private savedFiltersService: SavedFiltersService,
    private snackBar: MatSnackBar,
    private router: Router,
    private snackbarArticlesService: SnackbarArticlesService,
    private logService: LogService,
    private sharedService: SharedService,
    private userService: UserService
  ) {
    this.profile = this.userService.getUserProfile();
  }

  ngOnInit(): void {

    this.route.data.subscribe(() => {

      if (typeof (this.route.snapshot.params['docId']) !== 'undefined') {
        this.dashboardService.updateResearchDetailsDocId(this.route.snapshot.params['docId']);
        this.dashboardService.updateMainLayoutMode('ResearchDetail');
      }

      if (typeof (this.route.snapshot.queryParams[Constants.prismAlertId]) !== 'undefined') {
        this.dataService.loadFilterPrismId(this.route.snapshot.queryParams[Constants.prismAlertId]).then(result => {
          if(result && result.hasOwnProperty('result') && result.result.hasOwnProperty('filter') && result.result.filter.hasOwnProperty('settings')) {
            let settings = JSON.parse(result.result.filter.settings);
            if(settings) {
              if(this.profile.user_id === result.result.filter.userId) {
                this.savedFiltersService.loadFilter(settings.collection, settings.id);
              } else {
                // console.log(`you do not own this filter!`);
                if(this.reportsStores.indexOf(settings.collection) >= 0 ) {
                  // console.log(`reports - loading non-owned filter @ [${settings.collection}]`);
                  this.sharedService.updateReportsLoadFilterAsNew(settings);
                } else {
                  if(this.livefeedStores.indexOf(settings.collection) >= 0 ) {
                    // console.log(`livefeed - loading non-owned filter @ [${settings.collection}]`);
                    this.sharedService.updateLivefeedLoadFilterAsNew(settings);
                  }
                }
              }
            }
          }
        }).catch((error: any) => {
          this.router.navigate([`/404/alertid/${this.route.snapshot.queryParams[Constants.prismAlertId]}`], {skipLocationChange: true});
        });
      }

    });

    this.mainLayoutModeSub = this.dashboardService.mainLayoutMode.subscribe(value => {
      this.mainLayoutMode = value;
    });

  }

  switchDashboardView($event: any) {
    if (this.class === 'reports') {
      this.class = 'livefeed';

      this.logService.track("dashboard_change_selected", false,{
        tab_selection: 'Live Feed'
      });

      this.logService.logPendo('Dashboard Change', {
        title: 'Live Feed',
      });

    } else {
      this.class = 'reports';

      this.logService.track("dashboard_change_selected", false,{
        tab_selection: 'Reports'
      });

      this.logService.logPendo('Dashboard Change', {
        title: 'Reports',
      });

    }
  }

  ngOnDestroy() {
    this.mainLayoutModeSub.unsubscribe();
  }

}
