import {
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {Observable, Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import {NgxPaginationModule} from 'ngx-pagination';
import {NgPipesModule} from 'ngx-pipes';

import {AzureSearchService} from '../../services/azuresearch/azuresearch.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {SearchService} from '../../services/search/search.service';
import {SharedService} from "../../services/shared/shared.service";
import {UserService} from '../../services/user/user.service';

import {Research} from '../../interface/research';

import {CeilPipe} from '../../pipes/ceil/ceil.pipe';
import {MomentDatePipe} from '../../pipes/momentDate/momentDate.pipe';
import {MomentModule} from 'ngx-moment';
import {Constants} from "../../constants/constants";
import {InlineLoadingComponent} from "../../features/inline-loading/inline-loading.component";
import {Favorite} from "../../interface/favorite";
import {AzssError} from "../../interface/azss";
import {environment as ENV} from "../../../environments/environment";

@Component({
  selector: 'iv-reports-view',
  standalone: true,
  imports: [
    CommonModule,
    CeilPipe,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    NgxPaginationModule,
    NgPipesModule,
    MomentDatePipe,
    RouterModule,
    MomentModule,
    InlineLoadingComponent
  ],
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() store: any;

  public azureSearchService = inject(AzureSearchService);
  public userService = inject(UserService);

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  selectedItem: any;

  // if more than one separate instance of ngx-pagination on a page (ie: reports / livefeed component)
  // then angular breaks BADLY... throwing hundreds of errors a second
  // Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  // ensure each instance (component) has a unique pagination id: <pagination-controls id="{{paginationId}}" ...
  // which must be referenced by ngFor: <ng-container *ngFor="let azrec of azss.results?.results | paginate: { id: paginationId, ...
  paginationId: string = uuidv4();

  reportsChipPrettyTextSubscription: Subscription = Subscription.EMPTY;
  chipPrettyText: string = '';

  reportsFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  favoritesSubscription: Subscription = Subscription.EMPTY;
  favoritesDb: Favorite[] = [];

  isProduction: boolean = false;

  constructor(
    public dashboardService: DashboardService,
    public searchService: SearchService,
    private sharedService: SharedService,
  ) {

    // subscribe to store for ui data per research collection
    this.researchSubscription = this.searchService.research$.subscribe((research) => {
      this.research = research;
      if(this.store) {
        let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.store.collection);
        if(newResearchItem !== undefined) {
            this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
        }
      }
    });

    this.isProduction = ENV.production !== undefined ? ENV.production : false;
  }

  ngOnInit(): void {

    this.reportsChipPrettyTextSubscription = this.sharedService.reportsChipPrettyText$.subscribe( (text: string) => {
      this.chipPrettyText = text;
    });

    this.reportsFacetsPrettyTextSubscription = this.sharedService.reportsFacetsPrettyText$.subscribe( (text: string) => {
      this.facetsPrettyText = text;
    });

    this.favoritesSubscription = this.userService.userFavorites$.subscribe((favorites: Favorite[]) => {
      this.favoritesDb = favorites;

      let azssError: any;
      let searchParameters: Object = {};
      let searchParametersNew: any = Object.assign({}, searchParameters);
      let idCount:number = 0;
      searchParametersNew.additionalFilters = `(id eq '01802467436734763482963946783428068374026783456')`;

      // need to ensure we are not requesting a page beyond what is available... for instance if you have 20 per page
      // and you are on page 3 and delete the 41st bookmark then future queries will fail as it is still trying
      // to request results for page 3
      let searchParametersOld = this.azureSearchService.get_search_parameters('Bookmarks');
      if((this.favoritesDb.length > 0) && (searchParametersOld.skip >= this.favoritesDb.length)) {
        searchParametersNew.skip = searchParametersOld.skip - searchParametersOld.top;
      }
      if(this.favoritesDb.length) {
        searchParametersNew.additionalFilters = `(`;
        this.favoritesDb.forEach(function (favorite: Favorite) {
          searchParametersNew.additionalFilters += `${idCount > 0 ? ' or ' : ''}(id eq '${favorite.id}')`;
          idCount += 1;
        });
        searchParametersNew.additionalFilters += `)`;
      }
      if ((azssError = this.azureSearchService.update_search_parameters('Bookmarks', searchParametersNew))) {
      }
      if(this.store.collection === 'Bookmarks') {
        this.azureSearchService.search('Bookmarks');
      }

    });

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['store']) {
      let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.store.collection);
      if(newResearchItem !== undefined) {
        // if(JSON.stringify(newResearchItem) !== JSON.stringify(this.researchItem)) {
        this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
        // }
      }
    }

  }

  public calcAge(date: any) {
    const time = (Date.now() - Date.parse(date)) / 1000;
    switch (true) {
      case (time < 1800) :
        return 'thirtymin';
      case (time < 14400) :
        return 'fourhour';
      case (time < 28800) :
        return 'today';
      default:
    }
  }

  public setActive(event: any, newValue: any) {
    this.selectedItem = newValue;
  }

  public toggleFavorite(item: any) {
    this.userService.toggleFavorite(item);
  }

  public isFavorite(id: any) {
    return this.userService.isFavorite(id);
  }

  public navigateToPage(q: string | undefined, page: number) {

    this.azureSearchService.set_first_results_fetched(this.store.collection, false);

    // navigate via search.service (getting odd results, not correct)
    // is this due to angular universal? - shouldn't be affecting this page?!
    this.searchService.setPage(this.store.collection, page);
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });

  }

  public removeChip(): void {
    this.azureSearchService.set_first_results_fetched(this.store.collection, false);
    this.dashboardService.clearAllFilters(this.store.collection);
    this.searchService.ss_set_collection(this.store.collection);
  }

  public activateChip(): void {
    this.dashboardService.updateShowReportFacets(true);
  }

  ngOnDestroy() {
    // this.dashboardService.clearReportsSearchBox();
    this.researchSubscription.unsubscribe();
    this.reportsChipPrettyTextSubscription.unsubscribe();
    this.reportsFacetsPrettyTextSubscription.unsubscribe();
    this.favoritesSubscription.unsubscribe();
  }

}
