import {Component, AfterViewInit, ViewChild, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";

import {debounceTime, distinctUntilChanged, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter, mergeMap, take} from "rxjs/operators";

import { Constants } from "../../constants/constants";
const livefeedStores: string[] = Constants.LivefeedStores;

import { AzureSearchService } from "../../services/azuresearch/azuresearch.service";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { DataService } from "../../services/data/data.service";
import { LivefeedNavService } from "../../services/livefeednav/livefeednav.service";
import { SavedFiltersService } from "../../services/savedfilters/savedfilters.service";
import { SearchService } from "../../services/search/search.service";
import { SharedService } from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";

import { LivefeedHelpComponent } from "../../dialogs/livefeed-help/livefeed-help.component";
import { LivefeedViewComponent } from "../../views/livefeed-view/livefeed-view.component";
import { DownloadsViewComponent } from '../../views/downloads-view/downloads-view.component';
import { NewResearchComponent } from "../new-research/new-research.component";
import { ReportsViewComponent } from "../../views/reports-view/reports-view.component";
import { SearchFiltersComponent } from "../../features/search-filters/search-filters.component";

import { ActiveFilter, createEmptyActiveFilter } from "../../interface/activefilter";
import {AzssError, AzssStore} from "../../interface/azss";
import { Research } from "../../interface/research";

import { SafeHtmlPipe } from "../../pipes/safe-html/safe-html.pipe";
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LogService} from '../../services/log/log.service';
import { ChatbotComponent } from 'src/app/features/chatbot/chatbot.component';
import {MomentModule} from "ngx-moment";
import {environment as ENV} from "../../../environments/environment";

@Component({
  selector: 'iv-livefeed-nav',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    ReactiveFormsModule,
    // LivefeedComponent,
    LivefeedHelpComponent,
    LivefeedViewComponent,
    SearchFiltersComponent,
    SafeHtmlPipe,
    ReportsViewComponent,
    DownloadsViewComponent,
    MatMenuModule,
    NewResearchComponent,
    MatSelectModule,
    MatTooltipModule,
    ChatbotComponent,
    MomentModule,
    RouterLink
  ],
  templateUrl: './livefeed-nav.component.html',
  styleUrls: ['./livefeed-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'navigation-tabs'}
    }]
})
export class LivefeedNavComponent implements OnInit, OnDestroy, AfterViewInit {

  searchCtrl = new FormControl();
  clearLivefeedSearchBoxSub: Subscription = Subscription.EMPTY;
  blockSearchCtrlInfinite: boolean = false;

  // main.service: controls whether livefeed facets are displayed
  showLivefeedFacetsSub: Subscription = Subscription.EMPTY;
  showLivefeedFacets: boolean = false;

  // search.service (instance): subscription and data matching the active store in livefeed-nav
  livefeedNavAzssData: any;  // AzssStore;
  livefeedNavAzssSubscription: Subscription;

  livefeedFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  activeFilterSub: Subscription = Subscription.EMPTY;
  activeFilter: ActiveFilter = createEmptyActiveFilter();

  filtersLoadedSub: Subscription = Subscription.EMPTY;
  defaultFilterId: number = 0;

  loadingSavedFilterSub: Subscription = Subscription.EMPTY;
  loadingSavedFilter: boolean = false;

  loadingSavedUrlFilterSub: Subscription = Subscription.EMPTY;
  loadingSavedUrlFilter: string = '';

  loadUrlFilterSub: Subscription = Subscription.EMPTY;

  loadingFilterAsNewSub: Subscription = Subscription.EMPTY;
  loadingFilterAsNew: any = null;

  // access the counts for new articles per tab
  tabCountsSub: Subscription = Subscription.EMPTY;
  tabCounts: any = {};

  activeStoreKey: string = '';  // livefeedStores[0];

  blockInfinite: boolean = false;

  orderBy: string = 'dateDesc';

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  suggestSubscription: Subscription = Subscription.EMPTY;

  lastSearchTerm: string = '';

  isProduction: boolean = false;

  selectedIcon: string = 'relevance';

  constructor(private route: ActivatedRoute,
      public dialog: MatDialog,
      private azureSearchService: AzureSearchService,
      private dashboardService: DashboardService,
      private dataService: DataService,
      private livefeedNavService: LivefeedNavService,
      private router: Router,
      private savedFiltersService: SavedFiltersService,
      public searchService: SearchService,
      public sharedService: SharedService,
      public userService: UserService,
      private logService:LogService
  ) {

    // when search.service store/data is changed, get the new data for search results
    this.livefeedNavAzssSubscription = this.searchService.livefeedAzss$.subscribe((data: any) => {
      this.livefeedNavAzssData = data;

      // also update an communication service so search-filters on the livefeed-nav pane gets updated
      this.livefeedNavService.setLivefeedNavAzssData( data );
    });

    this.loadingSavedUrlFilterSub = this.sharedService.livefeedSavedUrlFilter$.subscribe((data:any) => {
      this.loadingSavedUrlFilter = data;
    });

    this.loadingFilterAsNewSub = this.sharedService.livefeedLoadFilterAsNew$.subscribe(filter => {
      if(filter) {
        this.loadingFilterAsNew = filter;
      }
    });

    this.loadUrlFilterSub = this.sharedService.reportsLoadUrl$.subscribe((urlkey: string) => {
      if((typeof(urlkey)==='string') && urlkey.length) {
        this.loadFilterUrl(urlkey);
      }
    });

    this.isProduction = ENV.production !== undefined ? ENV.production : false;
  }

  ngOnInit(): void {

    // start the reports tab on a specific tab
    if (typeof (this.route.snapshot.queryParams[Constants.livefeedPaneTab]) !== 'undefined') {
      this.setCollection(this.route.snapshot.queryParams[Constants.livefeedPaneTab]);
    }

    this.clearLivefeedSearchBoxSub = this.dashboardService.clearLivefeedSearchBox$.subscribe( value => {
      if(this.searchCtrl && value) {
        this.searchCtrl.setValue('');
      }
    });

    this.showLivefeedFacetsSub = this.dashboardService.showLivefeedFacets.subscribe(value => {
      this.showLivefeedFacets = value;
    });

    this.tabCountsSub = this.searchService.newCounts.subscribe(value => {
      this.tabCounts = value;
    });

    this.activeFilterSub = this.sharedService.livefeedActiveFilter$.subscribe( af => {
      this.activeFilter = af;
      if(!this.blockSearchCtrlInfinite) {
        if ((this.activeStoreKey === af.collection) && af.hasOwnProperty('q') && (af.q !== undefined)) {
          this.blockSearchCtrlInfinite = true;
          setTimeout(() => {
            this.searchCtrl.setValue(this.activeFilter.q);
            this.blockSearchCtrlInfinite = false;
          });
        }
      }
    });

    this.loadingSavedFilterSub = this.sharedService.livefeedLoadingSavedFilter$.subscribe( value=> {
      this.loadingSavedFilter = value;
    });

    this.livefeedFacetsPrettyTextSubscription = this.sharedService.livefeedFacetsPrettyText$.subscribe( (text: string) => {
      this.facetsPrettyText = text;
    });

    let defaultFilterIdSet = false; // Flag to track if defaultFilterId is set
    this.filtersLoadedSub = this.savedFiltersService.filtersLoaded$.pipe(
      filter(filtersLoaded => filtersLoaded), // Wait until filtersLoaded is true
      switchMap(() => this.processLoadedFiltersAndSelectDefault().pipe(
        tap((defaultFilterId: number) => {
          if ((this.loadingSavedUrlFilter === '') && (!this.loadingFilterAsNew)) {
            this.defaultFilterId = defaultFilterId;
            defaultFilterIdSet = true; // Set the flag when defaultFilterId is set
          }
        })
      )),
      mergeMap(() => this.sharedService.livefeedTab$) // livefeedTabSub subscription occurs after this point
    ).subscribe(value => {

      if(this.activeStoreKey === '') {
        // start the pane with a specific search query
        this.activeStoreKey = value;
        if (typeof (this.route.snapshot.queryParams[Constants.livefeedPaneQuery]) !== 'undefined') {
          this.sharedService.updateLivefeedBlockNextSuggest(true);
          this.searchService.setQuery(this.activeStoreKey, this.route.snapshot.queryParams[Constants.livefeedPaneQuery]);
          this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
          }).catch((error: any) => {
          });
        }

      } else {
        this.activeStoreKey = value;
      }

      if(!this.blockInfinite) {

        if(this.loadingFilterAsNew) {
          let filter: any = this.sharedService.getLivefeedLoadFilterAsNew();
          if(filter) {
            this.loadFilterAsNew(filter);
          }
        }

        if(this.loadingSavedUrlFilter !== '') {
          const urlkey = this.route.snapshot.queryParams[Constants.livefeedSavedFiltersUrl];

          this.loadingSavedUrlFilter = '';

          if((typeof(urlkey)==='string') && urlkey.length) {
            this.loadFilterUrl(urlkey);
          }

        } else {
          if (defaultFilterIdSet) {
            if (this.defaultFilterId > 0) {
              this.sharedService.updateLivefeedLoadingSavedFilter(true);
            }
            this.setCollection(value);
            if (this.defaultFilterId > 0) {
              this.sharedService.updateLivefeedLoadingSavedFilter(false);
            }
            // this.livefeedTab = value;
            this.activeStoreKey = value;
          }
        }

      }

    });

    this.selectedIcon = this.sharedService.getCookie(Constants.sortLivefeedPaneCookie) || 'relevance';

  }

  loadFilterUrl(urlkey: string): void {

    this.dataService.loadFilterUrl(urlkey).then(result => {

      let filter = result.result.filter.filter;
      let q = filter.q;

      // set the facets to the loaded filter
      this.blockInfinite = true;

      this.sharedService.updateLivefeedTab( filter.collection );
      this.azureSearchService.clear_all_facets( filter.collection, false );
      this.azureSearchService.set_facets_diff( filter.collection, filter.facetsdiff );
      this.searchService.ss_set_subscription( filter.collection, '', false );
      this.azureSearchService.facetsDiff_to_facets( filter.collection, true );
      this.searchService.setQuery(filter.collection, q);
      this.searchService.ss_set_collection( filter.collection, true );
      this.activeStoreKey = filter.collection;

      this.blockInfinite = false;

    });

  }


  loadFilterAsNew(filter: any): void {
    let q = filter.q;

    // set the facets to the loaded filter
    this.blockInfinite = true;

    this.sharedService.updateLivefeedTab( filter.collection );
    this.azureSearchService.clear_all_facets( filter.collection, false );
    this.azureSearchService.set_facets_diff( filter.collection, filter.facetsDiff );
    this.searchService.ss_set_subscription( filter.collection, '', false );
    this.azureSearchService.facetsDiff_to_facets( filter.collection, true );
    this.searchService.setQuery(filter.collection, q);
    this.searchService.ss_set_collection( filter.collection, true );
    this.activeStoreKey = filter.collection;

    this.blockInfinite = false;
  }

  ngAfterViewInit(): void {

    this.researchSubscription = this.searchService.research$.subscribe((research) =>{
      this.research = research;
      const researchRec: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.activeStoreKey);
      if(researchRec !== undefined) {
        this.researchItem = JSON.parse(JSON.stringify( researchRec ));
      }
    });

    this.suggestSubscription = this.searchCtrl.valueChanges.pipe(
      debounceTime(Constants.suggestDebounceMs),
      distinctUntilChanged(),
      tap(val => {
        if (typeof (val) === 'string') {
          if (val.length) {
            if (this.autocomplete) {
              if(this.sharedService.getLivefeedBlockNextSuggest() === false) {
                this.autocomplete.openPanel();
              }
            }
          } else {
            if (this.autocomplete) {
              this.autocomplete.closePanel();
            }
          }
        }
      }),
      switchMap(val => {
        if(typeof(val) === 'string') {
          this.searchService.setQuery(this.activeStoreKey, val);
          if (typeof (val) === 'string' && Constants.suggestResults) {
            // Perform the side-effect, then return an empty observable.
            if (this.sharedService.getLivefeedBlockNextSuggest() === false) {
              this.searchService.suggest(this.activeStoreKey, val);
            } else {
              this.sharedService.updateLivefeedBlockNextSuggest(false);
            }
            return of([]); // Return a no-op observable to satisfy the type requirement
          }
        }
        // Ensure an Observable is returned from all paths
        return of([]);
      })
    ).subscribe(results => {
      // Handle the observable update from the other service here if needed
    });

  }

  processLoadedFiltersAndSelectDefault(): Observable<number> {
    return this.sharedService.livefeedDefaultFilterId$;
  }

  public setCollection(collection: string, userSelected: boolean = false) {

    if( livefeedStores.indexOf(collection) >= 0 ) {

      let activeFilterId: number = this.savedFiltersService.getActiveFilterId(collection);

      // otherwise if a default filter, load it (but only the first time!)
      if(activeFilterId === 0) {
        if(!this.savedFiltersService.getDefaultFilterLoaded(collection)) {
          this.savedFiltersService.setDefaultFilterLoaded(collection);
          activeFilterId = this.savedFiltersService.getDefaultFilterId(collection);
        }
      }

      if(activeFilterId > 0) {

        if (!this.loadingSavedFilter) {
          this.savedFiltersService.loadFilter(collection, activeFilterId);
        }

      } else {

        this.blockSearchCtrlInfinite = true;
        this.sharedService.clearActiveFilter(collection);
        const researchRec: Research | undefined = this.research.find(rec => rec.activeStoreKey === collection);
        if(researchRec !== undefined) {
          this.sharedService.updateActiveFilterParameter(collection, 'q', researchRec.q);
          this.sharedService.updateActiveFilterParameter(collection, 'collection', collection);
        }
        this.blockSearchCtrlInfinite = false;

        if (collection === 'LiveFeedNew') {
          this.dashboardService.updateShowLivefeedFacets(false);
        }

        this.activeStoreKey = collection;

        if (!this.blockInfinite) {
          this.blockInfinite = true;
          this.sharedService.updateLivefeedTab(collection);
          this.blockInfinite = false;
        }

        if (!this.loadingSavedFilter) {
          this.searchService.ss_set_collection(collection);
        }

      }

    }

    // ONLY log this event if the user clicked a tab to change it (was triggering every dashboard redraw for every pane)
    if(userSelected) {
      this.logService.track("collection_change_selected", false,{
        vault_collection: collection
      });

      this.logService.logPendo('Collection Change', {
        title: collection,
      });
    }

  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
    this.searchService.ss_set_order_by(this.activeStoreKey, orderBy);
    this.userService.setPreferences({search: {orderby: orderBy}});
    this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });
  }

  public showFilters($event: any) {
    this.dashboardService.updateShowLivefeedFacets(!this.showLivefeedFacets);
  }

  public showFilterHelp($event: any) {
    const dialogRef = this.dialog.open(LivefeedHelpComponent, {
      panelClass: 'iv-livefeed-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '300px',
      height: 'auto',
      data: ''
    });
  }

  public quote(q: string) {
    return '"' + q + '"';
  }

  public htmlToPlaintext(text: string) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  public navigateToPage(q: string | undefined, page: number) {
    this.searchService.setPage(this.activeStoreKey, page);
    this.searchService.search(this.activeStoreKey).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });
  }

  public clearSearchText(event: any) {
    if (this.autocomplete) {
      this.autocomplete.closePanel();
    }
    this.searchService.setQuery(this.activeStoreKey, '');
    this.sharedService.updateActiveFilterParameter(this.activeStoreKey, 'q', '');
    setTimeout(() => {
      this.searchCtrl.setValue('');  // NEW
    });
    this.searchService.navigateToPage(this.activeStoreKey,'',1);
    this.searchService.checkActiveFilterChanged(this.activeStoreKey, '');
  }

  public navigateToSuggested(suggestion: any) {
    if(this.autocomplete) {
      this.autocomplete.closePanel();
    }
    this.dashboardService.clearLivefeedSearchBox();
    this.router.navigate([`/research/${suggestion.id}`]);
    this.searchCtrl.setValue(this.lastSearchTerm, { emitEvent: false });
  }

  getSuggestionId(index: number, suggestion: any): string {
    return suggestion.id;
  }

  performSearch(q: string) {
    if(this.autocomplete) {
      this.autocomplete.closePanel();
    }
    this.searchService.navigateToPage(this.activeStoreKey, q, 1);
    this.sharedService.updateActiveFilterParameter(this.activeStoreKey, 'q', q);
    this.searchService.checkActiveFilterChanged(this.activeStoreKey, '');
  }

  closeAutocomplete(): void {
    if(this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  public captureSearchTerm(event: Event) {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    this.lastSearchTerm = inputElement.value;
  }

  selectIcon(icon: string) {
    this.selectedIcon = ( icon === 'relevance' ? 'relevance' : 'calendar_month' );
    switch(icon) {
      case 'relevance':
        this.searchService.ss_set_order_by(this.activeStoreKey, '');
        break;
      case 'calendar_month':
        this.searchService.ss_set_order_by(this.activeStoreKey, 'publishedDate desc');
        break;
    }
    this.sharedService.setCookie(Constants.sortLivefeedPaneCookie, icon, Constants.sortOrderExpiryDays);
    this.searchService.ss_set_collection(this.activeStoreKey);
    this.azureSearchService.dump_stores(this.activeStoreKey);
  }

  ngOnDestroy() {
    this.clearLivefeedSearchBoxSub.unsubscribe();  // <-- what is this?
    this.livefeedNavAzssSubscription.unsubscribe();
    if(this.researchSubscription !== Subscription.EMPTY) {
      this.researchSubscription.unsubscribe();
    }
    this.showLivefeedFacetsSub.unsubscribe();
    this.tabCountsSub.unsubscribe();
    this.activeFilterSub.unsubscribe();
    this.filtersLoadedSub.unsubscribe();
    if(this.loadUrlFilterSub !== Subscription.EMPTY) {
      this.loadUrlFilterSub.unsubscribe();
    }
    this.loadingSavedFilterSub.unsubscribe();
    if (this.loadingFilterAsNewSub !== Subscription.EMPTY) {
      this.loadingFilterAsNewSub.unsubscribe();
    }
    this.livefeedFacetsPrettyTextSubscription.unsubscribe();
    if(this.suggestSubscription !== Subscription.EMPTY) {
      this.suggestSubscription.unsubscribe();
    }
  }

}
