import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location, LocationStrategy} from '@angular/common';

@Component({
  selector: 'iv-four-o-four-alertid-page',
  standalone: true,
  templateUrl: './four-o-four-alertid-page.component.html',
  styleUrls: ['./four-o-four-alertid-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FourOFourAlertidPageComponent implements OnInit {
  public attemptedUrl = '';
  alertId: string | null = null;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private locationStrategy: LocationStrategy) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.attemptedUrl = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.alertId = this.route.snapshot.paramMap.get('alertId');
  }

}
