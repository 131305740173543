import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject, Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterModule } from "@angular/router";

import { Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';

import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxPaginationModule } from "ngx-pagination";
import { NgPipesModule } from "ngx-pipes";

import { AzureSearchService } from "../../services/azuresearch/azuresearch.service";
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { SearchService } from "../../services/search/search.service";
import {SharedService} from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";

import {AzssError, AzssStore} from "../../interface/azss";
import { ResearchStore } from "../../store/research-store/research.store";
import { Research } from "../../interface/research";

import { CeilPipe } from "../../pipes/ceil/ceil.pipe";
import { MomentDatePipe } from "../../pipes/momentDate/momentDate.pipe";
import {MomentModule} from 'ngx-moment';
import {Constants} from "../../constants/constants";
import {InlineLoadingComponent} from "../../features/inline-loading/inline-loading.component";
import {environment as ENV} from "../../../environments/environment";

@Component({
  selector: 'iv-livefeed-view',
  standalone: true,
    imports: [
        CommonModule,
        CeilPipe,
        MatChipsModule,
        MatIconModule,
        MatTooltipModule,
        NgxPaginationModule,
        NgPipesModule,
        MomentDatePipe,
        RouterLink,
        RouterModule,
        MomentModule,
        InlineLoadingComponent
    ],
  templateUrl: './livefeed-view.component.html',
  styleUrls: ['./livefeed-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LivefeedViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() store: any;

  public azureSearchService = inject( AzureSearchService );
  public userService = inject( UserService );

  research: Research[] = [];
  researchItem: Research | undefined;
  researchSubscription: Subscription = Subscription.EMPTY;

  selectedItem: any;

  // if more than one seperate instance of ngx-pagination on a page (ie: reports / livefeed component)
  // then angular breaks BADLY... throwing hundreds of errors a second
  // Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  // ensure each instance (component) has a unique pagination id: <pagination-controls id="{{paginationId}}" ...
  // which must be referenced by ngFor: <ng-container *ngFor="let azrec of azss.results?.results | paginate: { id: paginationId, ...
  paginationId: string = uuidv4();

  livefeedChipPrettyTextSubscription: Subscription = Subscription.EMPTY;
  chipPrettyText: string = '';

  livefeedFacetsPrettyTextSubscription: Subscription = Subscription.EMPTY;
  facetsPrettyText: string = '';

  isProduction: boolean = false;

  constructor(
    private readonly _researchStore: ResearchStore,
    public dashboardService: DashboardService,
    public searchService: SearchService,
    private sharedService: SharedService,
  ) {

    // subscribe to store for ui data per research collection
    this.researchSubscription = this.searchService.research$.subscribe((research) =>{
      this.research = research;
      if(this.store) {
        let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.store.collection);
        if(newResearchItem !== undefined) {
            this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
        }
      }
    });

    this.isProduction = ENV.production !== undefined ? ENV.production : false;
  }

  ngOnInit(): void {

    this.livefeedChipPrettyTextSubscription = this.sharedService.livefeedChipPrettyText$.subscribe( (text: string) => {
      this.chipPrettyText = text;
    });

    this.livefeedFacetsPrettyTextSubscription = this.sharedService.livefeedFacetsPrettyText$.subscribe( (text: string) => {
      this.facetsPrettyText = text;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['store']) {
      let newResearchItem: Research | undefined = this.research.find(rec => rec.activeStoreKey === this.store.collection);
      if(newResearchItem !== undefined) {
        this.researchItem = JSON.parse(JSON.stringify(newResearchItem));
      }
    }

  }

  public calcAge(date: any) {
    const time = (Date.now() - Date.parse(date)) / 1000;
    switch (true) {
      case (time < 1800) :
        return 'thirtymin';
      case (time < 14400) :
        return 'fourhour';
      case (time < 28800) :
        return 'today';
      default:
    }
  }

  public setActive(event: any, newValue: any) {
    this.selectedItem = newValue;
  }

  public toggleFavorite(item: any) {
    this.userService.toggleFavorite(item);
  }

  public isFavorite(id: any) {
    return this.userService.isFavorite(id);
  }



  public navigateToPage(q: string | undefined, page: number) {

    this.azureSearchService.set_first_results_fetched(this.store.collection, false);

    // navigate via search.service (getting odd results, not correct)
    // is this due to angular universal? - shouldn't be affecting this page?!
    this.searchService.setPage(this.store.collection, page);
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });

  }

  public removeChip(): void {
    this.azureSearchService.set_first_results_fetched(this.store.collection, false);
    this.dashboardService.clearAllFilters(this.store.collection);
    this.searchService.ss_set_collection(this.store.collection);
  }

  public activateChip(): void {
    this.dashboardService.updateShowLivefeedFacets(true);
  }

  ngOnDestroy() {
    this.researchSubscription.unsubscribe();
    this.livefeedChipPrettyTextSubscription.unsubscribe();
    this.livefeedFacetsPrettyTextSubscription.unsubscribe();
  }

}
