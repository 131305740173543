import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, QueryList,
  SimpleChanges,
  ViewChild, ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionModule, MatExpansionPanel} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {Observable, Subscription} from 'rxjs';

import {environment as ENV} from "../../../environments/environment";
import {Constants} from '../../constants/constants';

const livefeedStores: string[] =Constants.LivefeedStores;
const reportsStores: string[] = Constants.ReportsStores;

import {AzureSearchService} from '../../services/azuresearch/azuresearch.service';
import {DataService} from '../../services/data/data.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {SavedFiltersService} from '../../services/savedfilters/savedfilters.service';
import {SearchService} from '../../services/search/search.service';
import {SharedService} from '../../services/shared/shared.service';
import {UserService} from "../../services/user/user.service";

import { ActiveFilter, createEmptyActiveFilter } from "../../interface/activefilter";
import {AzssError, AzssStore} from '../../interface/azss';
import {LoadedFilters} from '../../interface/loadedfilters';
import {ResearchStore} from '../../store/research-store/research.store';

import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog/confirm-dialog.component';
import {DateFilterComponent} from '../date-filter/date-filter.component';
import {FilterHelpComponent} from '../../dialogs/filter-help/filter-help.component';
import {SubscriptionFilterComponent} from '../subscription-filter/subscription-filter.component';

import {SafeHtmlPipe} from '../../pipes/safe-html/safe-html.pipe';
import {SearchPipe} from '../../pipes/search/search.pipe';
import {map, take} from 'rxjs/operators';
import {ShareArticleDialogComponent} from '../../dialogs/share-article-dialog/share-article-dialog.component';
import {ShareFilterDialogComponent} from '../../dialogs/share-filter-dialog/share-filter-dialog.component';
import {resolve} from '@angular/compiler-cli';
import {KeywordSearchHelpComponent} from '../../dialogs/keyword-search-help/keyword-search-help.component';
import {FilterSubscribeDialogComponent} from '../../dialogs/filter-subscribe-dialog/filter-subscribe-dialog.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatMenuModule} from "@angular/material/menu";
import {LogService} from '../../services/log/log.service';
import {ScrollingModule} from "@angular/cdk/scrolling";

@Component({
  selector: 'iv-search-filters',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateFilterComponent,
    SubscriptionFilterComponent,
    SafeHtmlPipe,
    SearchPipe,
    MatButtonToggleModule,
    MatMenuModule,
    ScrollingModule,
  ],
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default  // OnPush
})
export class SearchFiltersComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() pane: string | undefined;
  @Input() store?: any;
  @Input() state: any;
  @Input() defaultFilterId: number | null = 0;

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  @ViewChild('savedFiltersExpPanel') savedFiltersExpPanel!: MatExpansionPanel;

  public azureSearchService = inject(AzureSearchService);

  debug_cfn: boolean = false;                              // show function call console debug info

  activeFilterSub: Subscription = Subscription.EMPTY;
  activeFilter: ActiveFilter = createEmptyActiveFilter();

  searchCtrl = new FormControl();       // keyword search input box
  filterNameCtrl = new FormControl()    // filter name input box

  private filterNameCtrlSub: Subscription = Subscription.EMPTY;

  clearReportsSearchBoxSub: Subscription = Subscription.EMPTY;
  clearLivefeedSearchBoxSub: Subscription = Subscription.EMPTY;

  // track the urlkey on the other pane (ie: if this is Livefeed, the urlkey for Reports)
  // used to determine if we can copy both urlkeys to clipboard
  otherPaneUrlkeySubscription: Subscription = Subscription.EMPTY;
  otherPaneUrlkey: string = '';

  // the currently opened filter (mat-expansion-panel)
  activePanel: string = '';

  public researchStore: ResearchStore;

  loadedFiltersSub: Subscription = Subscription.EMPTY;
  loadedFilters: LoadedFilters[] = [];
  collections: string[] = [];

  searchTerm: string = '';

  currentProtocol: string = '';
  currentDomain: string = '';
  currentPort: string = '';
  filtersUrl: string = '';

  blockSearchCtrlInfinite: boolean = false;
  blockfilterNameCtrlInfinite: boolean = false;

  profile: any;
  preferences: any;
  orderBy: string = '';

  allowFilterPublish: boolean = false;

  taggingErrorMsg: string = '';

  filteredFacets: any = null;
  facetValues: { [key: string]: any } = {};

  constructor(
    public dialog: MatDialog,
    public dashboardService: DashboardService,
    public dataService: DataService,
    public savedFiltersService: SavedFiltersService,
    public searchService: SearchService,
    public sharedService: SharedService,
    private userService: UserService,
    private _researchStore: ResearchStore,
    private cdr: ChangeDetectorRef,
    private logService:LogService
  ) {
    if(this.debug_cfn) { console.log(`%c search-filters::constructor()`, 'background: pink; color: black'); }

    this.researchStore = _researchStore;

    this.currentProtocol = window.location.protocol;
    this.currentDomain = window.location.hostname;
    this.currentPort = window.location.port;

    this.preferences = this.userService.getPreferences();
    this.orderBy = this.preferences.search.orderby === 'dateDesc' ? 'dateDesc' : '';

    this.profile = this.userService.getUserProfile();
    if( ENV.SEGMENTS.publishUserIds.indexOf(this.profile.user_id) >= 0 ) {
      this.allowFilterPublish = true;
    }

  }

  ngOnInit(): void {
    if(this.debug_cfn) { console.log(`%c search-filters::ngOnInit()`, 'background: pink; color: black'); }

    this.loadedFiltersSub = this.savedFiltersService.loadedFilters$.subscribe((filters) => {
      this.loadedFilters = this.filterLoadedFiltersbyPane(filters);
      this.updateCollections();
    });

    this.clearReportsSearchBoxSub = this.dashboardService.clearReportsSearchBox$.subscribe((value: boolean) => {
      if (value) {
        setTimeout(() => {
          this.searchCtrl.setValue('');
          this.taggingErrorMsg = '';
        });
      }
    });

    this.clearLivefeedSearchBoxSub = this.dashboardService.clearLivefeedSearchBox$.subscribe((value: boolean) => {
      if (value) {
        setTimeout(() => {
          this.searchCtrl.setValue('');
          this.taggingErrorMsg = '';
        });
      }
    });

    if( reportsStores.indexOf(this.store.collection) >= 0 ) {
      this.activeFilterSub = this.sharedService.reportsActiveFilter$.subscribe( af=> {
        this.blockfilterNameCtrlInfinite = true;
        this.activeFilter = af;
        this.filterNameCtrl.setValue(this.activeFilter.title);
        this.blockfilterNameCtrlInfinite = false;
      });
    } else {
      if (livefeedStores.indexOf(this.store.collection) >= 0) {
        this.activeFilterSub = this.sharedService.livefeedActiveFilter$.subscribe( af=> {
          this.blockfilterNameCtrlInfinite = true;
          this.activeFilter = af;
          this.filterNameCtrl.setValue(this.activeFilter.title);
          this.blockfilterNameCtrlInfinite = false;
        });
      }
    }

    if (this.pane === 'Reports') {
      this.otherPaneUrlkeySubscription = this.sharedService.livefeedSavedUrlFilter$.subscribe(value => {
        this.otherPaneUrlkey = value;
      });
    } else {
      this.otherPaneUrlkeySubscription = this.sharedService.reportsSavedUrlFilter$.subscribe(value => {
        this.otherPaneUrlkey = value;
      });
    }

    this.filterNameCtrlSub = this.filterNameCtrl.valueChanges.subscribe(newValue => {
      if (!this.blockfilterNameCtrlInfinite) {
        this.searchService.checkActiveFilterChanged(this.store.collection, this.filterNameCtrl.value);
      }
    });

  }

  ngAfterViewInit(): void {
    if(this.debug_cfn) { console.log(`%c search-filters::ngAfterViewInit()`, 'background: pink; color: black'); }

    // expand saved filters and segements panel on open
    setTimeout(() => {
      this.savedFiltersExpPanel.open();
    });

    if (this.state && this.state.q) {
      setTimeout(() => {
        this.searchCtrl.setValue(this.state.q);
      });
    }

    // let searchCtrl = this.searchFiltersGroup.get('searchCtrl');
    this.searchCtrl.valueChanges.subscribe((val) => {
      if (!this.blockSearchCtrlInfinite) {
        if (typeof (val) === 'string') {
          if (this.autocomplete) {
            this.autocomplete.openPanel();
          }
          this.searchService.setQuery(this.store.collection, val);
          if(Constants.suggestResults) {
            this.searchService.suggest(this.store.collection, val);
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['store']) {
      if(this.debug_cfn) { console.log(`%c search-filters::ngOnChanges(changes[store])`, 'background: pink; color: black'); }

      this.filtersUrl = '';
    }

    if (changes['state']) {
      if(this.debug_cfn) { console.log(`%c search-filters::ngOnChanges(changes[state])`, 'background: pink; color: black'); }

      const newState = changes['state'].currentValue;

      this.filteredFacets = { ts: Date.now(), facets: structuredClone(this.store.facets.facets) };

      this.store.facets.facets.forEach((facet: any) => {
        if((facet.type === 'CheckboxFacet') && (facet.key != 'acl')) {
          this.updateFilteredFacets(facet.key);
        }
      });

      this.blockSearchCtrlInfinite = true;
      // need to delay setValue() to next tick of event loop using setTimeout(), else throws:
      // Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
      setTimeout(() => {
        if(this.searchCtrl && newState) {
          this.searchCtrl.setValue(newState.q);  // , {emitEvent: false}  <-- prevents main page search box from updating!
        }
        this.blockSearchCtrlInfinite = false;
      });
    }

  }

  filterLoadedFiltersbyPane(filters: LoadedFilters[]): LoadedFilters[] {
    if(this.debug_cfn) { console.log(`%c search-filters::filterLoadedFiltersbyPane()`, 'background: pink; color: black'); }
    return filters.filter(filter => {
      switch (this.pane) {
        case 'Reports':
          return reportsStores.indexOf(filter.settings.collection) >= 0;
        case 'Livefeed':
          return livefeedStores.indexOf(filter.settings.collection) >= 0;
        default:
          return false;
      }
    });
  }

  updateCollections() {
    if(this.debug_cfn) { console.log(`%c search-filters::updateCollections()`, 'background: pink; color: black'); }
    this.collections = []; // Reset the collections array
    this.loadedFilters.forEach((filter) => {
      if (!this.collections.includes(filter.settings.collection)) {
        this.collections.push(filter.settings.collection); // Store unique collections in order
      }
    });
  }

  globalFiltersCount(): number {
    // if(this.debug_cfn) { console.log(`%c search-filters::globalFiltersCount()`, 'background: pink; color: black'); }
    let count = 0;
    this.loadedFilters.forEach((filter) => {
      if(filter.isGlobal) {
        count += 1;
      }
    });
    return count;
  }

  public mapFilterTitle(key: string) {
    const titlesMap: any = {
      savedfilters: 'Saved Filters',
      publishedDate: 'Date Range',
      projectType: 'Intelligence Type',
      acl: 'Intelligence Type',
      series: 'Report Series',
      researchType: 'RS Research Type',
      type: 'Class',
      subType: 'Sub Class',
      category: 'Category',
      subCategory: 'Sub Category',
      solutionSet: 'Solution Set',
      modelAuthors: 'Authors',
      authors: 'Authors',
      companies: 'Companies',
      stockTickers: 'Stock Tickers',
      countries: 'Countries',
      regions: 'Regions',
      basins: 'Basins',
      plays: 'Plays',
      intervals: 'Intervals',
      financialView: 'Financial View',
      keywords: 'Keywords'
    };
    return titlesMap[key] || key;
  }

  public setActivePanel(panel: string) {
    if(this.debug_cfn) { console.log(`%c search-filters::setActivePanel(${panel})`, 'background: pink; color: black'); }

    this.logService.track("filter_section_changed",false,{
      filter_selection: this.mapFilterTitle(panel)
    });

    this.logService.logPendo('Filter Section Change', {
      title: this.mapFilterTitle(panel)
    });

    this.activePanel = panel;
  }

  public logFilter(filter: any, active: boolean) {

    if (active) {

      this.logService.track("filter_activated",false,{
        filter_category: this.mapFilterTitle(this.activePanel),
        filter_individual: filter.value,
      });

      this.logService.logPendo('Filter Activated', {
        key: this.mapFilterTitle(this.activePanel),
        title: filter.value,
      });

    } else {

      this.logService.track("filter_deactivated", false,{
        filter_category: this.mapFilterTitle(this.activePanel),
        filter_individual: filter.value,
      });

      this.logService.logPendo('Filter De-Activated', {
        key: this.mapFilterTitle(this.activePanel),
        title: filter.value,
      });

    }
  }

  public showFilterHelp($event: any) {
    const dialogRef = this.dialog.open(FilterHelpComponent, {
      panelClass: 'rsv-filter-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: ''
    });
  }

  public subscribeToFilter(event: any, filter: any){
    if(this.debug_cfn) { console.log(`%c search-filters::subscribeToFilter(${event},${filter})`, 'background: pink; color: black'); }
    // placeholder
    if (event) {
      event.stopPropagation();
    }

    const dialogRef = this.dialog.open(FilterSubscribeDialogComponent, {
      panelClass: 'rsv-filter-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: filter
    });

  }

  public keywordSearchHelp(event: any){
    // placeholder
    if (event) {
      event.stopPropagation();
    }

    const dialogRef = this.dialog.open(KeywordSearchHelpComponent, {
      panelClass: 'rsv-filter-help-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: ''
    });
  }

  public cloneFilter() {
    if(this.debug_cfn) { console.log(`%c search-filters::cloneFilter()`, 'background: pink; color: black'); }
    if (this.activeFilter) {
      let newActiveFilter = this.activeFilter;
      newActiveFilter.id = 0;
      newActiveFilter.titleOriginal = this.activeFilter.title;
      newActiveFilter.qOriginal = this.activeFilter.q;
      newActiveFilter.title += ' (2)';
      newActiveFilter.filterModified = true;
      newActiveFilter.isGlobal = false;

      if (reportsStores.indexOf(this.store.collection) >= 0) {
        this.sharedService.updateReportsActiveFilter(newActiveFilter);

      } else {
        if (livefeedStores.indexOf(this.store.collection) >= 0) {
          this.sharedService.updateLivefeedActiveFilter(newActiveFilter);

        }
      }

    }
  }

  public saveFilter() {
    if(this.debug_cfn) { console.log(`%c search-filters::saveFilter()`, 'background: pink; color: black'); }
    if (this.activeFilter) {

      let filterByTitle = this.savedFiltersService.getLoadedFilterByTitle(this.activeFilter.title);
      if(filterByTitle && (filterByTitle.id !== this.activeFilter.id)) {
        this.taggingErrorMsg = 'A filter with that title already exists!  You must use another title.';

      } else {

        this.activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff(this.store.collection, Constants.facetsModifiedIgnoreDates || false);  // this.searchService.ss_active_storeKey
        this.state.activeFilterJSON = JSON.stringify(this.activeFilter);
        this.savedFiltersService.saveFilter(this.store.collection, this.activeFilter, this.state.q).then((updatedFilter) => {  // this.searchService.ss_active_storeKey
          this.activeFilter = JSON.parse(JSON.stringify(updatedFilter));
        });

        this.taggingErrorMsg = '';
      }

    }

  }

  public clearFilter() {
    if(this.debug_cfn) { console.log(`%c search-filters::clearFilter()`, 'background: pink; color: black'); }
    this.dashboardService.clearAllFilters(this.store.collection);
    this.sharedService.updateActiveFilterParameter(this.store.collection, 'title', '');
    this.filterNameCtrl.setValue('');
    this.taggingErrorMsg = '';

    this.logService.track("clear_all_filtered", false,{});

    this.logService.logPendo('Clear All Filters', {});
  }

  public async loadFilter(event: any, filter: any, copyUrlToClipboard: boolean = false, allowUnload: boolean = true, share: boolean = false, subscribe: boolean = false) {
    if (event) {
      event.stopPropagation();
    }
    if(this.debug_cfn) { console.log(`%c search-filters::loadFilter(${event},${filter.id})`, 'background: pink; color: black'); }

    if (this.activeFilter) {

      // if clicked id is not the currently active filter
      if (filter.id !== this.activeFilter.id) {

        if (reportsStores.indexOf(this.store.collection) >= 0) {
          this.sharedService.updateReportsLoadingSavedFilter(true);
          this.sharedService.updateReportsBlockNextSuggest(true);
        } else {
          if (livefeedStores.indexOf(this.store.collection) >= 0) {
            this.sharedService.updateLivefeedLoadingSavedFilter(true);
            this.sharedService.updateLivefeedBlockNextSuggest(true);
          }
        }

        this.savedFiltersService.loadFilter(this.store.collection, filter.id).then(async () => {
          if (reportsStores.indexOf(this.store.collection) >= 0) {
            this.sharedService.reportsActiveFilter$.pipe(
              take(1),
            ).subscribe((activeFilter: ActiveFilter) => {
              this.activeFilter = activeFilter;
            });
            this.sharedService.updateReportsLoadingSavedFilter(false);
          } else {
            if (livefeedStores.indexOf(this.store.collection) >= 0) {
              this.sharedService.livefeedActiveFilter$.pipe(
                take(1),
              ).subscribe((activeFilter: ActiveFilter) => {
                this.activeFilter = activeFilter;
              });
              this.sharedService.updateLivefeedLoadingSavedFilter(false);
            }
          }
          await this.getFiltersUrl(filter.id);
          if (share) {
            const dialogRef = this.dialog.open(ShareFilterDialogComponent, {
              panelClass: 'iv-share-dialog',
              disableClose: false,
              autoFocus: true,
              width: '600px',
              height: 'auto',
              data: {filter: this.activeFilter, url: `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`}
            });
          }
          if (subscribe) {
            filter.url = `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`;
            const dialogRef = this.dialog.open(FilterSubscribeDialogComponent, {
              panelClass: 'rsv-filter-help-dialog',
              disableClose: false,
              autoFocus: true,
              width: '600px',
              height: 'auto',
              // data: {filter: filter, url: `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`}
              data: filter
            });
          }
          if (copyUrlToClipboard) {
            this.getFiltersUrl(filter.id,true);
          }

          this.logService.track("filter_loaded", false,{
            filter_name: this.activeFilter.title,
            filter_selection: this.activeFilter.prettyText
          });

          this.logService.logPendo('Filter Activated', {
            title: this.activeFilter.title,
            filters: this.activeFilter.prettyText
          });

          this.cdr.detectChanges();
        });

        // otherwise clear the active filter
      } else {

        if (allowUnload) {

          // if there is a default filter, activate it (unless it is the current filter)
          if (this.defaultFilterId && (this.defaultFilterId > 0) && (this.defaultFilterId != filter.id)) {
            this.searchService.setQuery(this.store.collection, '');
            this.searchService.setChipContents(this.store.collection, '');
            this.searchService.setPage(this.store.collection, 1);
            this.savedFiltersService.loadFilter(this.store.collection, this.defaultFilterId).then(() => {
              this.cdr.detectChanges();
            });

            // otherwise deactivate clicked filter (go back to baseline)
          } else {
            this.searchService.setQuery(this.store.collection, '');
            this.searchService.setChipContents(this.store.collection, '');
            this.dashboardService.clearSearchBox(this.store.collection);
            this.searchService.setPage(this.store.collection, 1);
            this.azureSearchService.clear_all_facets(this.store.collection, false);
            this.azureSearchService.set_subscription_filters(this.store.collection, JSON.parse(JSON.stringify(this.searchService.subscriptionFilterTemplate)));
            this.searchService.ss_set_subscription(this.store.collection, 'my-subscriptions');
            this.azureSearchService.diff_facets(this.store.collection);
            this.savedFiltersService.clearFilters(this.store.collection);
            this.activeFilter = this.savedFiltersService.initActiveFilter(this.store.collection);  // BUG
            this.searchService.ss_set_collection(this.store.collection);
            this.sharedService.clearActiveFilter(this.store.collection,
              this.azureSearchService.get_facetsdiff(this.store.collection, Constants.facetsModifiedIgnoreDates || false)
            );
          }

        } else {

          await this.getFiltersUrl(filter.id);
          if (share) {
            const dialogRef = this.dialog.open(ShareFilterDialogComponent, {
              panelClass: 'iv-share-dialog',
              disableClose: false,
              autoFocus: true,
              width: '600px',
              height: 'auto',
              data: {filter: this.activeFilter, url: `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`}
            });
          }
          if (subscribe) {
            filter.url = `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`;
            const dialogRef = this.dialog.open(FilterSubscribeDialogComponent, {
              panelClass: 'rsv-filter-help-dialog',
              disableClose: false,
              autoFocus: true,
              width: '600px',
              height: 'auto',
              // data: {filter: filter, url: `${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`}
              data: filter
            });
          }
          if (copyUrlToClipboard) {
            this.getFiltersUrl(filter.id,true);
          }

        }

      }

    }

  }

  public async deleteFilter(event: any, id: any) {
    event.stopPropagation();
    if(this.debug_cfn) { console.log(`%c search-filters::deleteFilter(${event},${id})`, 'background: pink; color: black'); }
    const filter = this.savedFiltersService.getLoadedFilter(id);

    let localTitle = '';
    if (filter) {
      localTitle = filter.title;
    }

    if (filter) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: `Are you sure to delete saved filter: ${localTitle} ?`
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          // if filter is active then we must deactivate before deleting
          if (this.activeFilter && (this.activeFilter.id == id)) {
            await this.loadFilter(null, id);
          }
          this.savedFiltersService.deleteFilter(this.store.collection, id).then(() => {

            // only clear out the filter if it is the current one
            if (this.activeFilter && (this.activeFilter.id === id)) {
              this.clearFilter();
            }

            this.logService.track("filter_deleted", false,{
              filter_name: filter.title,
              filter_selection: filter.prettyText
            });

            this.logService.logPendo('Filter Deleted', {
              title: filter.title,
              filters: filter.prettyText
            });

          });
        }
      });
    }
  }

  public publishFilter(event: any, id: any, isGlobal: boolean) {
    event.stopPropagation();
    if(this.debug_cfn) { console.log(`%c search-filters::publishFilter(${event},${id})`, 'background: pink; color: black'); }
    const filter = this.savedFiltersService.getLoadedFilter(id);

    let localTitle = '';
    if (filter) {
      localTitle = filter.title;
    }

    if (filter) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: `Are you sure to ${isGlobal ? 'publish' : 'un-publish'} saved filter to the public: ${localTitle} ?`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.savedFiltersService.publishFilter(filter.id, isGlobal).then(() => {
          });
        }
      });
    }
  }

  public toggleDefault(event: any, id: number) {
    event.stopPropagation();
    if(this.debug_cfn) { console.log(`%c search-filters::toggleDefault(${event},${id})`, 'background: pink; color: black'); }
    const filter = this.savedFiltersService.getLoadedFilter(id);
    if(filter !== null) {
      const storeDefaultFilterId: number = this.savedFiltersService.getDefaultFilterId(filter.settings.collection);
      this.savedFiltersService.updateFilterActive(filter.settings.collection, id, !(storeDefaultFilterId === id));
      this.sharedService.updateDefaultFilterId(filter.settings.collection, this.savedFiltersService.getDefaultFilterId(filter.settings.collection));

      this.logService.track(filter.settings.isDefault ? "filter_default_added" : "filter_default_removed", false,{
        filter_name: filter.title,
        filter_selection: filter.prettyText
      });

      this.logService.logPendo(`Filter Default ${(filter.settings.isDefault) ? 'Added' : 'Deleted'}`, {
        title: filter.title,
        filters: filter.prettyText
      });

    }
  }

  public searchFiltersClose() {
    if(this.debug_cfn) { console.log(`%c search-filters::searchFiltersClose()`, 'background: pink; color: black'); }
    switch (this.pane) {
      case 'Reports':
        this.dashboardService.updateShowReportFacets(false);
        break;
      case 'Livefeed':
        this.dashboardService.updateShowLivefeedFacets(false);
        break;
    }
  }

  public clearSearchText(event: any) {
    if(this.debug_cfn) { console.log(`%c search-filters::clearSearchText()`, 'background: pink; color: black'); }
    if (this.autocomplete) {
      this.autocomplete.openPanel();
    }
    this.searchService.setQuery(this.store.collection, '');
    this.sharedService.updateActiveFilterParameter(this.store.collection, 'q', '');
    setTimeout(() => {
      this.searchCtrl.setValue('');
      this.taggingErrorMsg = '';
    });
    this.searchService.navigateToPage(this.store.collection, '', 1);
    this.searchService.checkActiveFilterChanged(this.store.collection, this.filterNameCtrl.value);
  }

  public htmlToPlaintext(text: string) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  public quote(q: string) {
    return '"' + q + '"';
  }

  public getFiltersUrl(filter_id: number, copyToClipboard: boolean = false): Promise<string | void> {
    if(this.debug_cfn) { console.log(`%c search-filters::getFiltersUrl(${filter_id})`, 'background: pink; color: black'); }
    return new Promise((resolve, reject) => {
      const filter = this.savedFiltersService.getLoadedFilter(filter_id);
      if (filter && filter.hasOwnProperty('settings')) {
        let payload = {
          collection: filter.settings.collection,
          facetsdiff: this.azureSearchService.get_facetsdiff(filter.settings.collection, Constants.facetsModifiedIgnoreDates || false),
          q: this.store.parameters.input
        };
        const urlkey: string = this.searchService.facetsDiffUrl(JSON.stringify(payload));
          this.dataService.saveFilterUrl(urlkey, filter_id, payload).then((result) => {
            this.filtersUrl = urlkey;
            if (this.pane == 'Reports') {
              this.sharedService.updateReportsSavedUrlFilter(urlkey);
            } else {
              this.sharedService.updateLivefeedSavedUrlFilter(urlkey);
            }
            resolve(urlkey);
          });
      } else {
        reject('');
      }
    });
  }

  public urlGenerator(): string {
    return (`${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane == 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}`);
  }

  public urlGeneratorBoth(): string {
    return (`${this.currentProtocol}//${this.currentDomain}${this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard?${this.pane === 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.filtersUrl}&${this.pane !== 'Reports' ? Constants.reportsSavedFiltersUrl : Constants.livefeedSavedFiltersUrl}=${this.otherPaneUrlkey}`);
  }

  public urlCopyToClipboard(both: boolean = false): void {
    const urlToCopy: string = (both ? this.urlGeneratorBoth() : this.urlGenerator());
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = urlToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  onCombineOptionChange(event: any, facet: any) {
    if(this.debug_cfn) { console.log(`%c search-filters::onCombineOptionChange(${event},${facet})`, 'background: pink; color: black'); }
    this.azureSearchService.set_facets_group_combine_using_and(this.store.collection, facet.key,
      event.value !== 'or');  // BUG - not working...
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
      this.updateFilteredFacets(facet.key);
    }).catch((error: any) => {
    });

    this.logService.track("search_mode_filtered", false,{
      filter_selection: facet.key,
      mode_selection: event.value
    });

    this.logService.logPendo('Search Mode', {
      key: facet.key,
      terms: event.value
    });

  }

  insertSpaceBeforeCapital(str: string): string {
    // Check if the input string is empty or has only one character
    if (str.length <= 1) {
      return str;
    }

    // Initialize the result with the first character
    let result = str.charAt(0);

    // Loop through the remaining characters
    for (let i = 1; i < str.length; i++) {
      const currentChar = str.charAt(i);

      // If the current character is an uppercase letter, add a space before it
      if (currentChar === currentChar.toUpperCase()) {
        result += ' ';
      }

      result += currentChar;
    }

    return result;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
    this.searchService.ss_set_order_by(this.store.collection, orderBy);
    this.userService.setPreferences({search: {orderby: orderBy}});
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });

  }

  // updateReportsSavedUrlFilter(rf: string)
  loadFilterUrl(urlkey: string): void {
    if (this.pane == 'Reports') {
      this.sharedService.updateReportsLoadUrl(urlkey);
      this.searchFiltersClose();
    } else {
      this.sharedService.updateLivefeedLoadUrl(urlkey);
      this.searchFiltersClose();
    }
  }

  getFilterId(index: number, filter: any): number {
    if(this.debug_cfn) { console.log(`%c search-filters::getFilterId(${index},${filter})`, 'background: pink; color: black'); }
    return filter.id;
  }

  public trackByFacetValue(index: number, facetValue: any): string {
    return facetValue.value;
  }

  private findReferenceProperties(original: any, clone: any) {
    const references: any[] = [];

    // Helper function to recursively compare properties
    function compareProps(origObj: any, cloneObj: any, path: string = '') {
      if (typeof origObj === 'object' && origObj !== null) {
        for (const key in origObj) {
          const origValue = origObj[key];
          const cloneValue = cloneObj[key];
          const currentPath = path ? `${path}.${key}` : key;

          if (typeof origValue === 'object' && origValue !== null) {
            if (origValue === cloneValue) {
              references.push(currentPath);
            }
            compareProps(origValue, cloneValue, currentPath);
          }
        }
      }
    }

    compareProps(original, clone);
    return references;
  }

  private toggleFilteredFacets(storeKey: string, facet: any, facetValue:any): void {
    for(let i: number = 0; i < this.filteredFacets.facets.length; i++) {
      if(this.filteredFacets.facets[i].key == facet.key) {
        // find facet value in group
        for(let j: number = 0; j < this.filteredFacets.facets[i].values.length; j++) {
          if(this.filteredFacets.facets[i].values[j].value == facetValue.value) {

            // toggle selected of facet value
            this.filteredFacets.facets[i].values[j].selected =
              !this.filteredFacets.facets[i].values[j].selected;

            this.filteredFacets.facets[i].values.sort((a: any, b: any) => {
              const aSelected = !!a.selected;
              const bSelected = !!b.selected;
              if (aSelected !== bSelected) {
                return aSelected ? -1 : 1;
              }
              return a.value.localeCompare(b.value, undefined, { sensitivity: 'case', caseFirst: 'upper' });
            });

            break;  // early out on success
          }
        }
        break;  // early out on success
      }
    }
  }

  searchFromFacet(storeKey: string, facet: any, facetValue:any, logStatus: boolean): void {
    if(this.debug_cfn) { console.log(`%c search-filters::searchFromFacet(${storeKey},${facet},${facetValue})`, 'background: pink; color: black'); }

    if(!this.isGlobalReadOnly()) {
      const prevFacetValue: any = structuredClone(facetValue);
      this.toggleFilteredFacets(storeKey, facet, prevFacetValue);
      this.searchService.ss_search_from_facet(storeKey, facet, prevFacetValue);
      this.logFilter(prevFacetValue, logStatus);
    }
  }

  isGlobalReadOnly(): boolean {
    return this.activeFilter && (this.activeFilter.isGlobal === true) && (this.profile.user_id !== this.activeFilter.userId);
  }

  setAllFacetCheckboxes($event: any, facet: any, state: boolean) {
    if(this.debug_cfn) { console.log(`%c search-filters::setAllFacetCheckboxes(${$event},${facet},${state})`, 'background: pink; color: black'); }
    const searchTerm: string = this.facetValues[facet.key] as string;
    this.azureSearchService.set_all_facet_checkboxes(this.store.collection, facet.key, state, searchTerm);
    this.searchService.setPage(this.store.collection, 1);
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
    }).catch((error: any) => {
    });
    setTimeout(() => {
      $event.source.toggle();
    }, 500);
  }

  private generateWordCombinations(inputString: string): string[] {
    const words: string[] = inputString.split(' ');
    const combinations: string[] = [];
    for (let i = 0; i < words.length; i++) {
      for (let j = i + 1; j <= words.length; j++) {
        combinations.push(words.slice(i, j).join(' '));
      }
    }

    // sort by word count, total length descending
    const sortedCombinations: string[] = combinations.sort((a, b) => {
      const wordCountA: number = a.split(' ').length;
      const wordCountB: number = b.split(' ').length;

      if (wordCountA !== wordCountB) {
        return wordCountB - wordCountA; // Sort by word count in descending order
      } else {
        return b.length - a.length; // If word counts are equal, sort by string length in descending order
      }
    });

    return sortedCombinations;
  }

  cleanString(inputString: string): string {
    let stringWithoutCommas: string = inputString.replace(/,/g, ' ');
    let trimmedString: string = stringWithoutCommas.trim();
    return trimmedString.replace(/\s+/g, ' ');
  }

  keywordsToFacets() {
    this.taggingErrorMsg = '';

    // get the current facets
    const prevFacets = this.azureSearchService.get_facets(this.store.collection, true);

    // get array of all {value,series} facet optinos in descending value.length order
    let facetOptions: any[] = this.azureSearchService.get_facets_for_keyword_conversion(this.store.collection);

    // split the keywords into all combinations of consecutive words, in word count, string length descending order
    let keywords = this.cleanString( this.searchCtrl.getRawValue() );
    // let kwCombos: string[] = this.generateWordCombinations(keywords);

    let done: boolean = false;
    let kwWorking = keywords;
    while(kwWorking.length && !done) {

      let kwCombos: string[] = this.generateWordCombinations(kwWorking);

      done = true;
      for(let cnt=0; cnt < kwCombos.length; cnt++) {
        let ndx: number = facetOptions.findIndex(obj => obj.value.toLowerCase() === kwCombos[cnt].toLowerCase());
        if(ndx >= 0) {

          const index = kwWorking.indexOf(kwCombos[cnt]);
          let kwork = kwWorking.slice(0, index) + kwWorking.slice(index + kwCombos[cnt].length);
          let kwork2 = kwork.replace(/\s+/g, ' ');
          kwWorking = kwork2.trim();

          this.azureSearchService.set_checkbox_facet(this.store.collection, facetOptions[ndx].key, facetOptions[ndx].value, true);

          done = false;
          break;
        }
      }

    }

    this.searchService.setQuery(this.store.collection, kwWorking);
    this.sharedService.updateActiveFilterParameter(this.store.collection, 'q', kwWorking);

    // only clear keyword box if no errors!
    this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
      if (azssError != null) {
        this.taggingErrorMsg = azssError.errmsg;
        setTimeout(() => {
          this.searchCtrl.setValue(kwWorking);
        });
      } else {
        this.taggingErrorMsg = '';
      }
      this.logService.logPendo('Search to Tagging', {});
    }).catch((error) => {
      this.taggingErrorMsg = 'These keywords when converted to facets yield 0 results. Revise your keywords.';
      setTimeout(() => {
        this.searchService.setQuery(this.store.collection, keywords);
        this.searchCtrl.setValue(keywords);

        this.azureSearchService.set_facets(this.store.collection, prevFacets);
        this.searchService.search(this.store.collection).then((azssError: AzssError | null) => {
        }).catch((error: any) => {
        });
      });
    });

  }

  clearSearchField(facetKey: string): void {
    this.facetValues[facetKey] = '';
  }

  doNothing(event: any): void {
    if (event) {
      event.stopPropagation();
    }
  }

  trackByFn(index: number, item: any): string {
    return item.value;
  }

  trackByKey(index: number, item: any): string {
    return item.key;
  }

  private copyAndFilterFacet(srcFacet: any, q: string): any {
    const dstFacet: any = structuredClone(srcFacet);

    // Filter the values array and overwrite the dstFacet.values
    if(q) {
      dstFacet.values = srcFacet.values.filter((val: any) =>
        val.value.toLowerCase().includes(q.toLowerCase()) || (val.selected === true)
      );
    }

    dstFacet.unfilteredFacetsCount = srcFacet.values.length;

    return dstFacet;
  }

  updateFilteredFacets(facetKey: string) {
    const srcFacet = this.store.facets.facets.find((facet: any) => facet.key === facetKey);
    const dstIndex = this.filteredFacets.facets.findIndex((facet: any) => facet.key === facetKey);
    if(dstIndex > -1) {
      this.filteredFacets.facets[dstIndex] = structuredClone(this.copyAndFilterFacet(srcFacet, this.facetValues[facetKey]));
    }
  }

  public keywordSearch(event: any) {
    if( reportsStores.indexOf(this.store.collection) >= 0 ) {
      this.sharedService.updateReportsBlockNextSuggest(true);
    } else {
      if( livefeedStores.indexOf(this.store.collection) >= 0 ) {
        this.sharedService.updateLivefeedBlockNextSuggest(true);
      }
    }
    this.searchService.navigateToPage(this.store.collection, this.searchCtrl.value, 1);
    this.sharedService.updateActiveFilterParameter(this.store.collection, 'q', this.searchCtrl.value);
    this.searchService.checkActiveFilterChanged(this.store.collection, this.filterNameCtrl.value)
  }

  public async updateAllFilteredFacets() {
    await this.searchService.ss_set_collection(this.store.collection);
    setInterval(() => {
      this.filteredFacets = {ts: Date.now(), facets: structuredClone(this.store.facets.facets)};
      this.store.facets.facets.forEach((facet: any) => {
        if ((facet.type === 'CheckboxFacet') && (facet.key != 'acl')) {
          this.updateFilteredFacets(facet.key);
        }
      });
    }, 2000);
  }

  ngOnDestroy() {
    if(this.debug_cfn) { console.log(`%c search-filters::ngOnDestroy()`, 'background: pink; color: black'); }
    if(this.activeFilterSub !== Subscription.EMPTY) {
      this.activeFilterSub.unsubscribe();
    }
    if(this.filterNameCtrlSub !== Subscription.EMPTY) {
      this.filterNameCtrlSub.unsubscribe();
    }
    this.clearReportsSearchBoxSub.unsubscribe();
    this.clearLivefeedSearchBoxSub.unsubscribe();
    this.otherPaneUrlkeySubscription.unsubscribe();

    if(this.loadedFiltersSub !== Subscription.EMPTY) {
      this.loadedFiltersSub.unsubscribe();
    }

  }

  protected readonly String = String;
}
