import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { IvauthService } from "../../services/ivauth/ivauth.service";

@Component({
  selector: 'iv-callback',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallbackComponent {

  constructor(private auth: AuthService, private ivauth: IvauthService) {
  }

}
