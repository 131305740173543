import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {WebsocketService} from './websocket.service';
import {ChatService, Message, Reference} from './chat.service';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormControl, FormsModule} from '@angular/forms';
import {v4 as uuidv4} from 'uuid';
import {MatIconModule} from '@angular/material/icon';
import {MessageComponent} from './message/message.component';
import {ReferencesComponent} from './references/references.component';
import {MessageClipboardDirective} from './message-clipboard/message-clipboard.directive';
import {RatingPanelComponent} from './rating-panel/rating-panel.component';
import {PromptActionsDirective} from './prompt-actions/prompt-actions.directive';
import {ClipboardIconComponent} from './message-clipboard/clipboard-icon.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ThinkingLoaderComponent} from './thinking-loader/thinking-loader.component';
import {MatDividerModule} from '@angular/material/divider';
import {HallucinationComponent} from './hallucination/hallucination.component';
import {CONVERSATION_STYLE} from './hallucination/conversation-style';
import {PromptInputComponent} from './prompt-input/prompt-input.component';
import {MatButtonModule} from '@angular/material/button';
import {TokenTrackerComponent} from './token-tracker/token-tracker.component';
import {SearchService} from '../../services/search/search.service';
import {IntelligenceTypeComponent} from './token-tracker/intelligence-type/intelligence-type.component';
import {LogService} from 'src/app/services/log/log.service';
import {environment as ENV} from '../../../environments/environment';
import {Constants} from "../../constants/constants";
import {ChatbotDisclosureComponent} from "../../dialogs/chatbot-disclosure/chatbot-disclosure.component";
import {FiltersChipComponent} from "./filters-chip/filters-chip.component";
import {ChatbotSidebarComponent} from "./chatbot-sidebar/chatbot-sidebar.component";
import {DateTime} from 'luxon';
import {MatSidenav, MatSidenavModule} from "@angular/material/sidenav";  // MatSideNav
import {IvauthService} from "../../services/ivauth/ivauth.service";
import {AzureSearchService} from "../../services/azuresearch/azuresearch.service";
import {DataService} from "../../services/data/data.service";
import {SharedService} from "../../services/shared/shared.service";
import {Subscription} from "rxjs";
import {InlineLoadingComponent} from "../inline-loading/inline-loading.component";
import {ChatbotMaintenanceComponent} from "../../dialogs/chatbot-maintenance/chatbot-maintenance.component";
import {UserService} from "../../services/user/user.service";
import {take} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {RephraseQuestionComponent} from "./rephrase-question/rephrase-question.component";

export const AUTHOR = {
  CHATBOT: 'chatbot',
  COMPLETED_MESSAGE: 'chatbot-end',
};


interface OriginalCheckboxFacet {
  key: string;
  type: "CheckboxFacet";
  dataType: string;
  count: number;
  sort: string;
  filterClause: string;
  facetClause: string;
  facetsCombineUsingAnd: boolean;
  values: {
    count: number;
    value: string;
    selected: boolean;
  }[];
}

interface OriginalRangeFacet {
  key: string;
  type: "RangeFacet";
  dataType: string;
  min: string;
  max: string;
  filterLowerBound: string;
  filterUpperBound: string;
  lowerBucketCount: number;
  middleBucketCount: number;
  upperBucketCount: number;
  filterClause: string;
  facetClause: string;
  values: [];
}

interface RemappedFacet {
  [key: string]: {
    logical?: string;
    values?: string[];
    startDate?: string;
    endDate?: string;
  };
}

interface BucketizedObjects {
  [bucketName: string]: any;
}

@Component({
  selector: 'iv-chatbot',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatSidenavModule,
    MatTooltipModule,
    NgOptimizedImage,
    MessageComponent,
    ReferencesComponent,
    MessageClipboardDirective,
    RatingPanelComponent,
    PromptActionsDirective,
    ClipboardIconComponent,
    ThinkingLoaderComponent,
    HallucinationComponent,
    PromptInputComponent,
    TokenTrackerComponent,
    IntelligenceTypeComponent,
    FiltersChipComponent,
    ChatbotSidebarComponent,
    InlineLoadingComponent,
    ChatbotMaintenanceComponent,
    RephraseQuestionComponent,
  ],
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  providers: [WebsocketService, ChatService],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotComponent implements OnDestroy, OnInit, AfterViewChecked {
  messageList: Message[] = [];
  currentAnswer: {
    response: string;
    references: Reference[];
    quotedReferenceList: Reference[];
    noQuotedReferenceList: Reference[];
    prismReferenceList: Reference[];
  } = {
    response: '',
    references: [],
    quotedReferenceList: [],
    noQuotedReferenceList: [],
    prismReferenceList: []
  };
  typing = false;
  uniqueId = uuidv4();
  AUTHOR = AUTHOR;
  conversationStyle = new FormControl<CONVERSATION_STYLE>(
    CONVERSATION_STYLE.PRECISE
  );
  rephraseQuestion: boolean = true;
  promptControl = new FormControl<string>('');
  usedToken = 0;
  clickedInterest = false;
  @Input() store: any;
  @Input() allowedFeature = false;

  disclosureAgreed: boolean = false;

  maintenanceModeSub: Subscription = Subscription.EMPTY;
  maintenanceMode: string = '';
  mmPollingInterval: any = null;

  // tracks if we have received an error in the format of:
  // '####ERROR: ####, error_message: {"statusCode": 429, "body": "Too Many Requests"}'
  errorState: boolean = false;
  errorCaptured: string = '';
  errorStatusCode: number = 0;

  cloudStatusSub: Subscription = Subscription.EMPTY;
  cloudStatus: string = 'invalid';

  conversationHistorySub: Subscription = Subscription.EMPTY;
  conversationHistory: any = null;

  conversationLoadingSub: Subscription = Subscription.EMPTY;
  public conversationLoading: boolean = false;

  conversationStyleSub: Subscription = Subscription.EMPTY;

  loadMoreHistorySub: Subscription = Subscription.EMPTY;

  isDevelopment: boolean = false;

  conversationHistoryOpenSub: Subscription = Subscription.EMPTY;
  conversationHistoryOpen: boolean = false;

  // @ts-ignore
  @ViewChild('historySidenav', { static: true }) historySidenav: MatSidenav;

  @ViewChild(PromptInputComponent) promptInputComponent!: PromptInputComponent;
  inputInitialized: boolean = false;

  isProduction: boolean = false;

  loadQuerySub: Subscription = Subscription.EMPTY;

  currentProtocol: string = '';
  currentDomain: string = '';
  currentPort: string = '';

  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private searchService: SearchService,
    private logService: LogService,
    private renderer: Renderer2,
    private azureSearchService: AzureSearchService,
    private dataService: DataService,
    private sharedService: SharedService,
    private ivauthService: IvauthService,
    private userService: UserService,
  ) {
    this.renderer.addClass(document.body, 'intel-chatbot')

    this.isProduction = ENV.production !== undefined ? ENV.production : false;

    this.currentProtocol = window.location.protocol;
    this.currentDomain = window.location.hostname;
    this.currentPort = window.location.port;

    chatService.messages.subscribe(async (message: Message) => {

      let messageContents = message.user_input ? message.user_input : '';
      if(message.user_input) {
        this.typing = false;
      }

      if (message.references && !this.errorState) {

        const referencesSorted = message.references.sort(
          (a: any, b: any) => a.reference_id - b.reference_id
        );
        const [quotedReferenceList, noQuotedReferenceList, prismReferenceList] =
          referencesSorted.reduce(
            ([quotedList, noQuotedList, prismList], reference) => {
              if(reference.type && reference.type == "Prism") {
                prismList = [...prismList, reference];
              } else if (reference.referenced) {
                quotedList = [...quotedList, reference];
              } else {
                noQuotedList = [...noQuotedList, reference];
              }
              return [quotedList, noQuotedList, prismList];
            },
            [[], [], []] as [Reference[], Reference[],Reference[]]
          );
        this.currentAnswer = {
          response: this.currentAnswer.response,
          references: referencesSorted,
          quotedReferenceList,
          noQuotedReferenceList,
          prismReferenceList
        };
      } else if (message.author === AUTHOR.CHATBOT) {

        // process error
        if (!this.errorState && messageContents && message.hasOwnProperty('error') && message.error) {
          this.errorState = true;
          this.errorCaptured = messageContents;
          if(message.hasOwnProperty('errorStatusCode') && message.errorStatusCode) {
            this.errorStatusCode = message.errorStatusCode;
          }
        }

        // suppress all prepended whitespace..., ie inbound packets: [], [], [\nBased....] (strip first 2 packets and then the \n
        let cleanedMessage: string;
        if (this.currentAnswer.response.length) {
          cleanedMessage = messageContents;
        } else {
          cleanedMessage = messageContents.replace(/^\s+/, '');
        }

        this.currentAnswer = {
          response: this.currentAnswer.response.concat(this.errorState ? '' : cleanedMessage), // messageContents
          references: [],
          quotedReferenceList: [],
          noQuotedReferenceList: [],
          prismReferenceList: []
        };
      } else if (message.author === AUTHOR.COMPLETED_MESSAGE) {

        // manage error state
        if (this.errorState) {
          this.currentAnswer.response = this.errorCaptured;
        }
        const {
          response: user_input,
          references,
          quotedReferenceList,
          noQuotedReferenceList,
          prismReferenceList
        } = this.currentAnswer;
        const finalMessage: any = {
          user_input,
          author: AUTHOR.CHATBOT,
          references,
          quotedReferenceList,
          noQuotedReferenceList,
          prismReferenceList,
          ratingExpanded: false,
          prompt_id: message.prompt_id,
        };
        if (this.errorState) {
          finalMessage.errorStatusCode = this.errorStatusCode;
        }
        this.messageList = [...this.messageList, finalMessage];

        // error state cleanup
        this.errorState = false;
        this.errorCaptured = '';
        this.errorStatusCode = 0;

        this.changeDetectorRef.detectChanges();
        this.usedToken = 0;
        this.currentAnswer = {
          response: '',
          references: [],
          quotedReferenceList: [],
          noQuotedReferenceList: [],
          prismReferenceList: []
        };
      }

    });

    this.isDevelopment = ENV.dev !== undefined ? ENV.dev : false;
  }

  ngOnInit() {
    const _self = this;

    // determine if they have already agreed to the current disclosure
    if(this.allowedFeature) {
      const da: string | null = sessionStorage.getItem(Constants.chatbotStorageKey);  // localStorage.
      if (da && da.length) {
        this.decryptData(ENV.CHATBOT.disclosureAes256Key, da).then(function (disclosureDate: string) {
          if (disclosureDate === Constants.chatbotDisclosureDate) {
            _self.disclosureAgreed = true;
            _self.changeDetectorRef.detectChanges();
          } else {
            _self.showDisclosure();
          }
        });
      } else {
        _self.showDisclosure();
      }
    }

    this.maintenanceModeSub = this.sharedService.chatbotMaintenanceMode$.subscribe((message: string) => {
      this.maintenanceMode = message;
      if(message.length) {
        this.startPolling();
      } else {
        this.stopPolling();
      }
    });

    this.testAndSetMaintenanceMode();

    this.cloudStatusSub = this.sharedService.chatbotWebsocketStatus$.subscribe((cloudStatus: string) => {
      this.cloudStatus = cloudStatus;
    });

    // load the user's chatbot history (list of conversation metadata)
    let history = this.sharedService.getChatbotHistory();
    if(!history || (Object.keys(history).length === 0)) {
      this.updateConversationHistory();
    }
    this.loadMoreHistorySub = this.sharedService.chatbotLoadMore$.subscribe((loadMore: boolean) => {
      let history = this.sharedService.getChatbotHistory();
      if(!history || (Object.keys(history).length === 0) || loadMore) {
        this.updateConversationHistory();
      }
    });


    // load a conversation
    this.conversationHistorySub = this.sharedService.chatbotConversation$.subscribe((history: any) => {
      if(history !== null) {
        this.conversationHistory = history;
        this.messageList = this.remapHistoryArray(history.history);
        this.sharedService.updateChatbotConversationStyle(history.history[0].temperature);
        this.uniqueId = history.conversation_id;
        this.sharedService.updateChatbotHistoryOpen(false);
        this.changeDetectorRef.detectChanges();
        this.latestConversationHistory();
      } else {
        this.restartConversation();
      }
    });

    this.conversationLoadingSub = this.sharedService.chatbotConversationLoading$.subscribe((status: boolean) => {
      this.conversationLoading = status;
    });

    this.conversationStyleSub = this.sharedService.chatbotConversationStyle$.subscribe((style: string) => {
      if(style !== null) {
        let styleNum: number = parseFloat(style);
        switch(styleNum) {
          case parseFloat(CONVERSATION_STYLE.CREATIVE):
            this.conversationStyle.setValue(CONVERSATION_STYLE.CREATIVE);
            break;
          case parseFloat(CONVERSATION_STYLE.BALANCED):
            this.conversationStyle.setValue(CONVERSATION_STYLE.BALANCED);
            break;
          default:
            this.conversationStyle.setValue(CONVERSATION_STYLE.PRECISE);
            break;
        }
      }
    });

    this.conversationHistoryOpenSub = this.sharedService.chatbotHistoryOpen$.subscribe((status: boolean) => {
      this.conversationHistoryOpen = status;
      if (status) {
        this.historySidenav.open();
      } else {
        this.historySidenav.close();
      }
    });

  }

  ngAfterViewChecked() {
    if (this.promptInputComponent && !this.inputInitialized) {
      if(this.loadQuerySub == Subscription.EMPTY) {
        this.loadQuerySub = this.sharedService.chatbotLoadQuery$.subscribe((query: string) => {
          if (query.length) {
            this.dataService.loadIAQuery(query).then(result => {
              this.typing = true;
              this.promptInputComponent.simulateUserInput(result.result.filter.query.query);
              this.inputInitialized = true; // To prevent multiple executions

              this.logService.track("ia_prompt_received", false,{
                query: result.result.filter.query.query,
                url: `${this.currentProtocol}//${this.currentDomain}${this.currentPort.length && this.currentPort !== '443' ? ':' + this.currentPort : ''}/dashboard/${Constants.chatbotQuery}/${query}`
              });
            });
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'intel-chatbot')

    this.sharedService.updateChatbotMaintenanceMode('');

    if(this.cloudStatusSub !== Subscription.EMPTY) {
      this.cloudStatusSub.unsubscribe();
    }
    if(this.conversationHistorySub !== Subscription.EMPTY) {
      this.conversationHistorySub.unsubscribe();
    }
    if(this.conversationLoadingSub !== Subscription.EMPTY) {
      this.conversationLoadingSub.unsubscribe();
    }
    if(this.conversationStyleSub !== Subscription.EMPTY) {
      this.conversationStyleSub.unsubscribe();
    }
    if(this.loadMoreHistorySub !== Subscription.EMPTY) {
      this.loadMoreHistorySub.unsubscribe();
    }
    if(this.conversationHistoryOpenSub !== Subscription.EMPTY) {
      this.conversationHistoryOpenSub.unsubscribe();
    }
    if(this.maintenanceModeSub !== Subscription.EMPTY) {
      this.maintenanceModeSub.unsubscribe();
    }
    if(this.loadQuerySub !== Subscription.EMPTY) {
      this.loadQuerySub.unsubscribe();
    }
    this.stopPolling();
  }

  transformEmailToLink(message: string): string {
    const emailRegex: RegExp = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    const transformedMessage: string = message.replace(emailRegex, '<a class="support-link" href="mailto:$1">$1</a>');
    return transformedMessage;
  }

  private async testAndSetMaintenanceMode() {
    const _self = this;
    const accessToken: string = await this.ivauthService.getAccessToken();
    this.dataService.getChatbotClientStatus(accessToken).then(function (result: any) {
      if(result.hasOwnProperty('code') && (typeof(result.code) === 'number') && (result.code > 0)) {
        if(!_self.maintenanceMode) {
          let transformedMessage: string = _self.transformEmailToLink(result.message);
          _self.sharedService.updateChatbotMaintenanceMode(transformedMessage.replace(/\n/g, '<br>'));
          _self.startPolling();
        }
      } else {
        if(_self.maintenanceMode) {
          _self.sharedService.updateChatbotMaintenanceMode('');
          _self.stopPolling();
        }
      }
    });

  }

  public setUsedTokens(tokenCount: number): void {
    this.usedToken = tokenCount;
  }

  extractPageNumberFromString(str: string): number {
    const pageNumberRegex: RegExp = /page=(\d+)/;
    const match = pageNumberRegex.exec(str);
    let pageNumber;
    if (match && match.length > 1) {
      pageNumber = parseInt(match[1], 10);
    } else {
      pageNumber = 1;
    }
    return pageNumber;
  }

  mergeObjects(prevHistory: any, historyNew: any): { mergedHistory: any, totalCount: number } {

    // Initialize mergedHistory from prevHistory, ensuring any needed array keys exist and are initialized as empty arrays if absent
    const mergedHistory: any = { ...prevHistory };
    if (historyNew.legend) {
      historyNew.legend.forEach((key: string) => {
        if (!mergedHistory.hasOwnProperty(key)) {
          mergedHistory[key] = []; // Initialize as an empty array if not present
        }
      });
    }

    let totalCount = 0; // Initialize totalCount to zero

    // Merge arrays based on keys in "legend"
    if (historyNew.legend && Array.isArray(historyNew.legend)) {
      historyNew.legend.forEach((key: string) => {
        if (historyNew[key] && Array.isArray(historyNew[key])) {
          // Check if the key exists in both objects and they are arrays
          if (Array.isArray(mergedHistory[key])) {
            // Use a Set to track unique conversation_ids
            const existingIds = new Set(mergedHistory[key].map((item: any) => item.conversation_id));

            // Merge arrays ensuring unique conversation_ids
            historyNew[key].forEach((item: any) => {
              if (!existingIds.has(item.conversation_id)) {
                mergedHistory[key].push(item); // Add new unique item
                existingIds.add(item.conversation_id);
              }
            });
          } else {
            mergedHistory[key] = [...historyNew[key]]; // Copy over if not present in prevHistory
          }
          totalCount += mergedHistory[key].length; // Update totalCount with the length of the merged array
        }
      });
    }

    // Overwrite other properties from historyNew, skipping those in 'legend'
    Object.keys(historyNew).forEach((key: string) => {
      if (!historyNew.legend.includes(key)) {
        mergedHistory[key] = historyNew[key];
      }
    });

    return { mergedHistory, totalCount };
  }

  async updateConversationHistory() {
    const _self = this;
    let prevHistory = this.sharedService.getChatbotHistory();
    if(!prevHistory) {
      prevHistory = {};
    }
    this.sharedService.updateChatbotHistoryLoading(true);
    const accessToken: string = await this.ivauthService.getAccessToken();
    this.dataService.getConversationHistory( accessToken, prevHistory ? prevHistory.next_page : 1).then(function (history: any) {
      if(history && history.hasOwnProperty('items') && Array.isArray(history.items)) {
        history.items.forEach((rec: any) => {
          rec.history['unixDT'] = DateTime.fromFormat(rec.history.timestamp, 'yyyy-MM-dd, HH:mm:ss', { zone: 'UTC' });
          rec.history['localTimestamp'] = rec.history['unixDT'].toLocal().toFormat('yyyy-MM-dd, h:mm a');
        });
        let [historyNew, historyOrder] = _self.bucketizeObjectsByDate(history.items);
        historyNew['legend'] = historyOrder;
        let { mergedHistory, totalCount} = _self.mergeObjects(prevHistory, historyNew);
        mergedHistory['legend'] = historyOrder;
        mergedHistory['count'] = totalCount;
        mergedHistory['total'] = history.count;
        mergedHistory['next_page'] = _self.extractPageNumberFromString(history.next_page);
        _self.sharedService.updateChatbotHistory(mergedHistory);
        _self.sharedService.updateChatbotHistoryLoading(false);
      } else {
        _self.sharedService.updateChatbotHistoryLoading(false);
      }
    });
  }

  async latestConversationHistory() {
    const _self = this;
    let prevHistory = this.sharedService.getChatbotHistory();
    if(!prevHistory) {
      prevHistory = {};
    }
    const accessToken: string = await this.ivauthService.getAccessToken();
    this.sharedService.updateChatbotHistoryLoading(true);
    this.dataService.getConversationHistory(accessToken, 1).then(function (history: any) {
      if(history && history.hasOwnProperty('items') && Array.isArray(history.items)) {
        history.items.sort((a: any, b: any) => {
          const timestampA = new Date(a.history.timestamp).getTime();
          const timestampB = new Date(b.history.timestamp).getTime();
          return timestampB - timestampA;
        });
        history = {...history, items: history.items.slice(0, 1)}  // we only need the newest
        if(prevHistory.hasOwnProperty('last7days') && history.hasOwnProperty('items')) {
          if(prevHistory.last7days.some((item: any) => item.conversation_id === history.items[0].conversation_id )) {
            prevHistory.last7days = prevHistory.last7days.filter((obj: any) => obj.conversation_id !== history.items[0].conversation_id);
          }
          if(!prevHistory.last7days.some((item: any) => item.conversation_id === history.items[0].conversation_id )) {
            history.items.forEach((rec: any) => {
              rec.history['unixDT'] = DateTime.fromFormat(rec.history.timestamp, 'yyyy-MM-dd, HH:mm:ss', {zone: 'UTC'});
              rec.history['localTimestamp'] = rec.history['unixDT'].toLocal().toFormat('yyyy-MM-dd, h:mm a');
            });
            let [historyNew, historyOrder] = _self.bucketizeObjectsByDate(history.items);
            historyNew['legend'] = historyOrder;
            let {mergedHistory, totalCount} = _self.mergeObjects(historyNew, prevHistory);
            mergedHistory['legend'] = historyOrder;
            mergedHistory['count'] = totalCount;
            mergedHistory['total'] = history.count;
            mergedHistory['next_page'] = _self.extractPageNumberFromString(history.next_page);
            _self.sharedService.updateChatbotHistory(mergedHistory);
          }
        }
        _self.sharedService.updateChatbotHistoryLoading(false);
      } else {
        _self.sharedService.updateChatbotHistoryLoading(false);
      }
    });
  }

  remapHistoryArray(inputArray: any) {
    let outputArray = inputArray.map((obj: any) => {
      if (obj.type === 'human') {
        return {
          action: 'sendmessage',
          product: obj.product,
          user_input: obj.content,
          timestamp: DateTime.fromFormat(obj.timestamp,'yyyy-MM-dd, HH:mm:ss', { zone: 'UTC' }),
          temperature: obj.temperature.toString()
        };
      } else {
        // split references into References and Additional References
        let refs: any[] = [];
        let arefs: any[] = [];
        let prefs: any[] = [];
        if(obj.hasOwnProperty('references')) {
          obj.references.forEach((ref: any) => {
            if (ref.url && ref.url.startsWith('http')) {
              if (ref.type !== 'Prism') {
                const url: URL = new URL(ref.url);
                if (url.searchParams) {
                  ref.url = url.pathname + '?' + url.searchParams;
                } else {
                  ref.url = url.pathname;
                }
              }
            }
            if (ref.type && ref.type === "Prism"){
              prefs.push(ref)
            } else if (ref.referenced) {
              refs.push(ref);
            } else {
              arefs.push(ref);
            }
          });
          prefs.sort((a, b) => {
            return parseInt(a.reference_id) - parseInt(b.reference_id);
          });
          refs.sort((a, b) => {
            return parseInt(a.reference_id) - parseInt(b.reference_id);
          });
          arefs.sort((a, b) => {
            return parseInt(a.reference_id) - parseInt(b.reference_id);
          });
        }
        return {
          user_input: obj.content.replace(/<[^>\n]*>/g, '').replace(/^\s+/, ''), // remove xml style tags and prepended whitespace
          author: 'chatbot',
          ratingExpanded: false,
          prompt_id: obj.prompt_id,
          quotedReferenceList: refs,
          noQuotedReferenceList: arefs,
          prismReferenceList:prefs,
          timestamp: DateTime.fromFormat(obj.timestamp,'yyyy-MM-dd, HH:mm:ss', { zone: 'UTC' })
        };
      }
    });

    // add in the date headers
    let currentDate: DateTime = DateTime.fromObject({ year: 2000, month: 1, day: 1 });
    for(let message of outputArray) {
      if(!message.timestamp.hasSame(currentDate, "year") ||
        !message.timestamp.hasSame(currentDate, "month") ||
        !message.timestamp.hasSame(currentDate, "day")
      ){
        message.header = this.formatDate(message.timestamp);
        currentDate = message.timestamp;
      } else {
        message.header = '';
      }
    }

    return outputArray;
  }

  formatDate(date: DateTime): string {
    const today: DateTime = DateTime.now().startOf('day');
    const yesterday: DateTime = today.minus({ days: 1 });
    if(date.hasSame(today, 'year') && date.hasSame(today, 'month') && date.hasSame(today, 'day')) {
      return 'Today';
    } else if(date.hasSame(yesterday, 'year') && date.hasSame(yesterday, 'month') && date.hasSame(yesterday, 'day')) {
      return 'Yesterday';
    } else if (date.startOf('day') >= today.startOf('day').minus({ days: 6 })) {
      return date.toLocal().toFormat('EEEE');
    } else {
      return date.toLocal().toFormat('MMMM dd, yyyy');
    }
  }

  bucketizeObjectsByDate(objects: any): [BucketizedObjects, string[]] {
    // Get current date
    const currentDate = DateTime.now();

    // Initialize buckets
    const buckets: any = {
      last7days: [],
      last30days: [],
      [currentDate.year.toString()]: []
    };

    // Iterate through each object and put them in appropriate buckets
    objects.forEach((obj: any) => {
      const unixDT = DateTime.fromISO(obj.history.unixDT).toLocal();

      if (unixDT >= currentDate.minus({ days: 7 })) {
        buckets.last7days.push(obj);
      } else if (unixDT >= currentDate.minus({ days: 30 })) {
        buckets.last30days.push(obj);
      } else if (unixDT.year === currentDate.year) {
        buckets[currentDate.year].push(obj);
      } else {
        // If it's a previous year, add it to the corresponding bucket
        if (!buckets[unixDT.year]) {
          buckets[unixDT.year] = [];
        }
        buckets[unixDT.year].push(obj);
      }
    });

    // Sort objects within each bucket by unixDT in descending order
    for (const bucketName in buckets) {
      buckets[bucketName].sort((a: any, b: any) => {
        const dateA: DateTime = DateTime.fromISO(a.history.unixDT).toLocal();
        const dateB: DateTime = DateTime.fromISO(b.history.unixDT).toLocal();
        return dateB.toMillis() - dateA.toMillis();
      });
    }

    // Determine the order of buckets
    const bucketOrder = Object.keys(buckets).sort((a, b) => {
      if (a === 'last7days') return -1; // Ensure 'last7days' comes first
      if (a === 'last30days' && b !== 'last7days') return -1; // Ensure 'last30days' comes before other years
      return parseInt(b) - parseInt(a); // Sort other years in descending order
    });

    return [buckets, bucketOrder];
  }

  remapObject(originalObject: { facets: (OriginalCheckboxFacet | OriginalRangeFacet)[] }): RemappedFacet {
    const remappedObject: RemappedFacet = {};

    originalObject.facets.forEach((facet) => {
      const { key, type } = facet;
      if (type === "CheckboxFacet") {
        const { facetsCombineUsingAnd, values } = facet;
        const logical = 'any';
        const valueList = values.map((val) => val.value);

        remappedObject[key] = {
          logical,
          values: valueList
        };
      } else if (type === "RangeFacet") {
        let startDateSelected: boolean = false;
        let endDateSelected: boolean = false;

        let { filterLowerBound, filterUpperBound } = facet;

        let mD: DateTime = DateTime.fromISO(Constants.chatbotMinDate);
        let flb: DateTime = DateTime.fromISO(filterLowerBound);
        if(flb < mD) {
          let mDiso = mD.toISO();
          if(mDiso) {
            filterLowerBound = mDiso;
          }
        }
        if(flb > mD) {
          startDateSelected = true;
        }
        let eD: DateTime = DateTime.fromISO(filterUpperBound);
        let now: DateTime = DateTime.now();
        if(eD.toISODate() !== now.toISODate()) {
          endDateSelected = true;
        }
        if(startDateSelected || endDateSelected) {
          remappedObject[key] = {
            startDate: filterLowerBound,
            endDate: filterUpperBound
          };
        }
      }
    });

    return remappedObject;
  }

  getActiveACLs(subscriptionFilters: any): string[] {
    const activeFilters = subscriptionFilters.filters.filter((filter: any) => filter.active && filter.key !== 'my-subscriptions');
    const activeACLs: string[] = activeFilters.reduce((acc: any, curr: any) => {
      const regex: RegExp = /'([^']+)'/g;
      let match;
      while (match = regex.exec(curr.filter)) {
        acc.push(match[1]);
      }
      return acc;
    }, []);
    return Array.from(new Set(activeACLs)).sort();
  }

  async sendUserMessage(user_input: string) {
    if (!user_input) {
      return;
    }

    this.testAndSetMaintenanceMode();

    this.sharedService.updateChatbotHistoryOpen(false);

    const facetsDiff = this.azureSearchService.get_facetsdiff(Constants.ChatbotFiltersStores[0]);

    let remapped: RemappedFacet = this.remapObject(facetsDiff);
    remapped['collection'] = {"values": ['Intelligence', 'Live Feed', 'Operator Profiles']};
    remapped['acls'] = {"values": this.getActiveACLs(facetsDiff.subscriptionFilters) };

    const accessToken: string = await this.ivauthService.getAccessToken();

    const message: Message = {
      action: 'sendmessage',
      product: 'intelligence',
      client: 'intelligence-vault',
      user_input,
      authorizer: accessToken,
      conversation_id: this.uniqueId,
      temperature: this.conversationStyle.value,
      refine_prompt: this.rephraseQuestion,
      filters: remapped,
      timestamp: DateTime.utc()
    };
    this.messageList = [...this.messageList, message];
    this.typing = true;
    this.promptControl.setValue('');
    this.chatService.sendMessage(message);
    this.changeDetectorRef.detectChanges();
  }

  restartConversation() {
    this.sharedService.clearChatbotSelectedConversation();
    this.messageList = [];
    this.currentAnswer = {
      response: '',
      references: [],
      quotedReferenceList: [],
      noQuotedReferenceList: [],
      prismReferenceList: []
    };
    this.typing = false;
    this.uniqueId = uuidv4();
    this.AUTHOR = AUTHOR;
    this.conversationStyle.setValue(CONVERSATION_STYLE.PRECISE);
    this.promptControl.setValue('');
    this.latestConversationHistory();
  }

  reconnect() {
    this.chatService.reconnect();
  }

  interested(value: boolean) {
    this.logService.logPendo('ChatBot Interest', {
      key: value,
    });
    this.clickedInterest = true;
  }

// Helper function to convert hex string to ArrayBuffer
  hexStringToArrayBuffer(hexString: string): ArrayBuffer {
    const buffer: Uint8Array = new Uint8Array(hexString.length / 2);
    for (let i: number = 0; i < hexString.length; i += 2) {
      buffer[i / 2] = parseInt(hexString.substring(i, i + 2), 16);
    }
    return buffer.buffer;
  }

  async encryptData(aes256key: string, data: string): Promise<string> {
    const keyBuffer: CryptoKey = await window.crypto.subtle.importKey(
      'raw',
      this.hexStringToArrayBuffer(aes256key),
      { name: 'AES-GCM' },
      false,
      ['encrypt']
    );

    const iv: Uint8Array = window.crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
    const encodedData: Uint8Array = new TextEncoder().encode(data);

    const encryptedBuffer: ArrayBuffer = await window.crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      keyBuffer,
      encodedData
    );

    // Concatenate IV and encrypted data, and encode as base64
    const combinedData: Uint8Array = new Uint8Array([...iv, ...new Uint8Array(encryptedBuffer)]);
    return btoa(String.fromCharCode.apply(null, Array.from(combinedData)));
  }

  async decryptData(aes256key: string, encryptedString: string): Promise<string> {
    const combinedData: Uint8Array = new Uint8Array(atob(encryptedString).split('').map(char => char.charCodeAt(0)));
    const iv: Uint8Array = combinedData.slice(0, 12);
    const encryptedBuffer: Uint8Array = combinedData.slice(12);

    const keyBuffer: CryptoKey = await window.crypto.subtle.importKey(
      'raw',
      this.hexStringToArrayBuffer(aes256key),
      { name: 'AES-GCM' },
      false,
      ['decrypt']
    );

    const decryptedBuffer: ArrayBuffer = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: iv },
      keyBuffer,
      encryptedBuffer
    );

    return new TextDecoder().decode(decryptedBuffer);
  }

  public showDisclosure() {
    const dialogRef = this.dialog.open(ChatbotDisclosureComponent, {
      panelClass: 'iv-chatbot-disclosure-dialog',
      disableClose: false,
      autoFocus: true,
      width: '600px',
      height: 'auto',
      data: ''
    });
    dialogRef.afterClosed().subscribe( async result => {
      if (result) {

        this.logService.track("chatbot_disclosure_agreed", false,{
          disclosure_agreed: true
        });

        this.logService.logPendo('ChatBot Disclosure Agreed', {
          key: true,
        });

        const encryptedData: string = await this.encryptData(ENV.CHATBOT.disclosureAes256Key, Constants.chatbotDisclosureDate);
        sessionStorage.setItem(Constants.chatbotStorageKey, encryptedData);  // localStorage.

        this.disclosureAgreed = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  toggleSidebar(): void {
    this.sharedService.updateChatbotHistoryOpen( !this.sharedService.getChatbotHistoryOpenState() );
  }

  toggleMaintenanceMode(): void {
    if(this.maintenanceMode.length) {
      this.sharedService.updateChatbotMaintenanceMode('');
    } else {
      let mMessage: string = 'Greetings!\n\nExcuse our dust — Instant Analyst is gearing up for a little makeover!'+
        ' We\'ll be sprucing things up from 7 pm CT on Friday, Jan 12th, to 10 am CT on Sunday, Jan 14th. During this time, you might find us playing a little hard to get when trying to connect or log in.\n\n' +
        'If you have any questions or concerns, our team at support@instantanalyst.com would love to hear from you.\n\nThank you for your patience!\n-The Instant Analyst Team';
      this.sharedService.updateChatbotMaintenanceMode(mMessage.replace(/\n/g, '<br>'));
    }
  }

  startPolling() {
    if (!this.mmPollingInterval) {
      this.mmPollingInterval = setInterval(() => {
        this.testAndSetMaintenanceMode();
      }, (Constants.chatbotMMPollRate * 1000)); // Poll every minute
    }
  }

  stopPolling() {
    if (this.mmPollingInterval) {
      clearInterval(this.mmPollingInterval);
      this.mmPollingInterval = null;
    }
  }

  onRephraseQuestionChange(newValue: boolean): void {
    this.rephraseQuestion = newValue;
  }

  protected readonly ChatbotSidebarComponent = ChatbotSidebarComponent;
}
