import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {AuthService} from '@auth0/auth0-angular';
import {map} from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'iv-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe, NgIf]
})

export class ErrorPageComponent {
  errorCode: string = '';
  path: string = '/dashboard';
  currentDate = new Date().toUTCString();
  user$ = this.auth.user$;
  code$ = this.user$.pipe(map((user) => JSON.stringify(user, null, 2)));

  constructor(private route: ActivatedRoute, private auth: AuthService) {
    this.route.data.subscribe(() => {
      if (typeof (this.route.snapshot.params['code']) !== 'undefined') {
        this.errorCode = this.route.snapshot.params['code'];
      }
      if (typeof (this.route.snapshot.queryParams['path']) !== 'undefined') {
        this.path = decodeURI(this.route.snapshot.queryParams['path']);
      }
    });
  }
}
